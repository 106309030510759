<template>
  <div class="cover-page">
    <NavBar>
      <template #right>
        <div :class="['operate', { 'operate-active': companyId }]" @click="confirmCompany">
          <span>确定</span>
        </div>
      </template>
    </NavBar>
    <div class="flex-main column scroll-y">
      <van-search
        ref="searchRef"
        v-model="searchKey"
        class="search"
        placeholder="请输入搜索关键词"
        shape="round"
        @search="searchCom"
      >
        <template #left-icon>
          <div class="align-center">
            <van-image width="16" height="16" :src="searchIcon" />
          </div>
        </template>
      </van-search>
      <div class="show-company">
        <ParentCompany v-for="item in companyList" :key="item.id" :parentCompany="item" />
      </div>
    </div>
  </div>
</template>
<script>
import searchIcon from '@/assets/componentIcon/search.png'
import { inject, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ParentCompany from '@/views/components/ParentCompany.vue'
export default {
  name: 'SettingSyncCompany',
  components: {
    ParentCompany
  },
  setup() {
    const searchKey = ref('')
    const store = useStore()
    const router = useRouter()
    if (!store.state.commonData.companyData) {
      store.dispatch('commonData/companyTreeRequest')
    }

    onMounted(() => {
      searchCom()
    })

    const companyId = inject('selectedCompanyId')

    const companyList = ref([])

    const searchCom = () => {
      companyList.value = deepSearch(searchKey.value.trim(), store.state.commonData.companyData)
    }

    const confirmCompany = () => {
      if (companyId.value) {
        router.go(-1)
      }
    }
    return {
      searchIcon,
      companyList,
      searchKey,
      companyId,
      confirmCompany,
      searchCom
    }
  }
}
// 查询公司名称
function deepSearch(val, origins) {
  let result = []
  for (let index = 0; index < origins.length; index++) {
    const el = origins[index]
    if (el.text.includes(val)) {
      result = result.concat(el)
    } else {
      if (el.children && el.children.length > 0) {
        result = result.concat(deepSearch(val, el.children))
      }
    }
  }
  return result
}
</script>
<style lang="scss" scoped>
.operate {
  color: #646566;
  &-active {
    color: #323233;
  }
}

.search {
  height: 50px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(32, 40, 74, 0.05);
  z-index: 100;
}
.show-company {
  max-height: calc(100% - 50px);
  overflow-y: scroll;
  padding-top: 8px;
}
</style>
