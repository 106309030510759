<template>
  <NavBar />
  <div class="sync">
    <div class="company">
      同步项目：
      <input type="text" class="company__input" @focus="findCompany" v-model="companyName" />
    </div>
    <div class="action">
      <van-button round block type="primary" @click="submitSyncCar">同步</van-button>
    </div>
  </div>
  <router-view v-slot="{ Component }">
    <transition :name="animationName">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import { provide, ref } from 'vue'
import { useRouter } from 'vue-router'
import http from '@/utils/http'
import { Toast } from 'vant'
import { useAnimation } from '@/hooks/useAnimation'

export default {
  setup() {
    const router = useRouter()

    // 是否使用动画
    const { animationName } = useAnimation()

    const companyName = ref('')
    const companyId = ref('')

    const updateCompanyId = id => {
      companyId.value = id
    }
    const updateCompanyName = com => {
      companyName.value = com
    }

    // 向下提供数据和方法，更新公司名称和公司ID

    provide('companyName', companyName)
    provide('selectedCompanyId', companyId)
    provide('updateCompanyId', updateCompanyId)
    provide('updateCompanyName', updateCompanyName)

    const findCompany = () => {
      router.push({
        name: 'SettingSyncCompany'
      })
    }

    const submitSyncCar = () => {
      http({
        loadText: '正在同步数据...',
        method: 'post',
        url: 'projectCar/synCarByCompanyId.html',
        data: {
          companyId: companyId.value
        }
      }).then(res => {
        Toast(res.message)
      })
    }
    return {
      findCompany,
      animationName,
      companyName,
      submitSyncCar
    }
  }
}
</script>
<style lang="scss" scoped>
.sync {
  padding-top: 16px;
  .company {
    font-size: 14px;
    line-height: 22px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    &__input {
      height: 30px;
      margin-right: 24px;
      flex: 1;
      padding: 0 8px;
    }
  }

  .action {
    margin: 16px;
  }
}
</style>
