<template>
  <NavBar>
    <template #right>
      <div :class="['operate', { 'operate-active': carNumber.length > 1 }]" @click="confirmCar">
        <span>确定</span>
      </div>
    </template>
  </NavBar>
  <div class="container">
    <van-search v-model="searchKey" placeholder="请输入4位搜索关键词" @update:model-value="onSearch" />
    <van-radio-group v-model="deviceId">
      <van-cell-group>
        <van-cell
          :title="item.carNumber + ' [' + item.deviceId + ']'"
          clickable
          @click="choseCar(item)"
          v-for="item in carList"
          :key="item.id"
        >
          <template #right-icon>
            <van-radio :name="item.deviceId" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import http from '@/utils/request'

export default defineComponent({
  name: 'GpsSearchCar',
  setup() {
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      carNumber: '',
      deviceId: '',
      searchKey: '',
      carList: []
    })
    const onSearch = () => {
      if (state.searchKey.trim().length < 4) {
        state.carList = []
        return
      }
      http({
        url: 'stats/carListByCarNumber.html',
        method: 'post',
        data: {
          carNumber: state.searchKey
        }
      }).then(res => {
        if (res.success) state.carList = res.data
      })
    }
    const confirmCar = () => {
      if (!state.carNumber && state.carNumber.length < 1) return
      store.commit('gpsAudit/updateState', { carNumber: state.carNumber, deviceId: state.deviceId })
      setTimeout(() => {
        router.go(-1)
      }, 500)
    }
    const choseCar = item => {
      const { carNumber, deviceId } = item
      state.carNumber = carNumber
      state.deviceId = deviceId
    }
    return {
      ...toRefs(state),
      confirmCar,
      onSearch,
      choseCar
    }
  }
})
</script>
<style lang="scss" scoped>
.operate {
  color: #646566;
  &-active {
    color: #323233;
  }
}
</style>
