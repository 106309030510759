<template>
  <div class="cover-page">
    <NavBar />
    <div class="container">
      <van-cell-group>
        <van-field label="车牌号码" v-model="carNumber" readonly @click="selectCar" placeholder="请选择车牌号码" />
        <van-field label="审核状态" v-model="statusText" readonly @click="pickStatus = true" />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" @click="confirm">查询</van-button>
      </div>
    </div>
    <van-popup :show="pickStatus" @click-overlay="pickStatus = false" round position="bottom">
      <van-picker
        title="选择审核状态"
        show-toolbar
        :default-index="showIndex"
        :columns="StatusList"
        @confirm="confirmStatus"
        @cancel="pickStatus = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const StatusList = [
  { text: '待审核', value: '1' },
  { text: '已审核', value: '2' },
  { text: '全部', value: '' }
]

export default defineComponent({
  name: 'MaintainSearch',
  setup() {
    const store = useStore()
    const router = useRouter()

    const pickStatus = ref(false)

    const carNumber = computed(() => store.state.maintain.carNumber)
    const deviceId = computed(() => store.state.maintain.deviceId)

    const auditStatus = computed(() => store.state.maintain.auditStatus)

    const statusText = computed(() => StatusList.find(el => el.value === auditStatus.value).text)

    const showIndex = computed(() => StatusList.findIndex(el => el.value === auditStatus.value))

    // onMounted(() => {
    //   if (store.state.maintain.carNumber && store.state.maintain.deviceId) {
    //     state.carNumber = store.state.maintain.carNumber
    //     state.deviceId = store.state.maintain.deviceId
    //   }
    // })
    const selectCar = () => {
      router.push({
        name: 'MaintainSearchCar'
      })
    }

    const confirmStatus = val => {
      // state.statusText = val.text
      // state.auditStatus = val.value
      pickStatus.value = false
      store.commit('maintain/updateState', { auditStatus: val.value })
    }
    const confirm = () => {
      // store.commit('maintain/updateState', state)
      setTimeout(() => {
        router.go(-1)
      }, 500)
    }
    return {
      carNumber,
      deviceId,
      auditStatus,
      statusText,
      showIndex,
      pickStatus,
      selectCar,
      StatusList,
      confirmStatus,
      confirm
    }
  }
})
</script>
