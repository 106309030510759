<template>
  <div class="card">
    <van-row>
      <van-col span="12">{{ data.carNumber }}</van-col>
      <van-col span="12">
        <span>
          司机:
          <span>{{ data.userName }}</span>
        </span>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="24">
        <span>
          维修内容:
          <span>{{ data.repairOrders ? data.repairOrders[0]?.costDetail : '' }}</span>
        </span>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="8">
        <span>
          维修金额:
          <span>{{ data.price / 100 }}</span>
          元
        </span>
      </van-col>
      <van-col span="8">
        <span v-if="data.payStatus == 1" class="orange">待支付</span>
        <span v-if="data.payStatus == 2" class="green">已支付</span>
        <span v-if="data.payStatus == 3" style="color: #bbbbbb">订单取消</span>
        <span v-if="data.payStatus == 4" class="green">已完成</span>
      </van-col>
      <van-col span="8">
        <span>
          <span v-if="data.auditStatus == 1" class="orange">待审核</span>
          <span v-if="data.auditStatus == 2" class="green">已审核</span>
          <span v-if="data.auditStatus == 3" style="color: #bbbbbb">订单取消</span>
          <span v-if="data.auditStatus == 4" style="color: #bbbbbb">撤销审核</span>
          <span v-if="data.auditStatus == 5" class="blue">无需审核</span>
        </span>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="24">
        <span>
          审核人员:
          <span>{{ data.auditUser }}</span>
        </span>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  height: 100px;
  box-sizing: border-box;
  overflow-y: hidden;
  background: #f2f2f2;
  font-size: 12px;
  margin: 4px 8px 4px;
  padding: 12px;
  border-radius: 6px;
  color: #455a6499;
  font-weight: normal;
  line-height: 18px;
}
</style>
