<template>
  <div class="tabs">
    <div :class="['tab', { active: !isModify }]" @click="$emit('select', false)">处理前</div>
    <div :class="['tab', { active: isModify }]" @click="$emit('select', true)">处理后</div>
  </div>
</template>

<script>
export default {
  props: {
    // 是否选择处理后的
    isModify: {
      type: Boolean,
      required: true
    }
  },
  emits: ['select']
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
@import '@/style/mixins.scss';
.tabs {
  @include tab-nav;
  .tab {
    flex: 1;
    text-align: center;
    &.active {
      color: $blue;
    }
  }
}
</style>
