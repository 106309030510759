<template>
  <div class="cover-page">
    <NavBar>
      <template #right>
        <div :class="['operate', { 'operate-active': companyId }]" @click="confirmCompany">
          <span>确定</span>
        </div>
      </template>
    </NavBar>
    <div class="container">
      <van-search
        v-model="searchKey"
        placeholder="请输入搜索关键词"
        @update:model-value="searchCom"
        class="search-input"
        ref="searchRef"
      />
      <div class="show-company">
        <ParentCompany v-for="item in companyList" :key="item.id" :parentCompany="item" />
      </div>
    </div>
  </div>
</template>
<script>
import { inject, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ParentCompany from '@/views/components/ParentCompany.vue'
export default {
  name: 'DeviceRelieveRecordQueryCompany',
  components: {
    ParentCompany
  },
  setup() {
    const searchKey = ref('')
    const store = useStore()
    const router = useRouter()
    if (!store.state.commonData.companyData) {
      store.dispatch('commonData/companyTreeRequest')
    }

    onMounted(() => {
      searchCom()
    })

    const companyId = inject('selectedCompanyId')

    const companyList = ref([])

    const searchCom = () => {
      companyList.value = deepSearch(searchKey.value.trim(), store.state.commonData.companyData)
    }

    const confirmCompany = () => {
      if (companyId.value) {
        router.go(-1)
      }
    }
    return {
      companyList,
      searchKey,
      companyId,
      confirmCompany,
      searchCom
    }
  }
}
// 查询公司名称
function deepSearch(val, origins) {
  let result = []
  for (let index = 0; index < origins.length; index++) {
    const el = origins[index]
    if (el.text.includes(val)) {
      result = result.concat(el)
    } else {
      if (el.children && el.children.length > 0) {
        result = result.concat(deepSearch(val, el.children))
      }
    }
  }
  return result
}
</script>
<style lang="scss" scoped>
.operate {
  color: #646566;
  &-active {
    color: #323233;
  }
}
.container {
  display: flex;
  flex-direction: column;
  .show-company {
    flex-grow: 1;
    overflow-y: scroll;
    padding-top: 8px;
  }
}
</style>
