<template>
  <NavBar />
  <div class="container optimize-wrapper">
    <div class="optimize-tab-list">
      <div @click="tabClick(0)" :class="[{ 'optimize-tab-active': currentTab == 0 }, 'optimize-tab']">
        <span class="optimize-tab__text">数据</span>
      </div>
      <div @click="tabClick(1)" :class="[{ 'optimize-tab-active': currentTab == 1 }, 'optimize-tab']">
        <span class="optimize-tab__text">优化</span>
      </div>
      <div class="optimize-tabs__line position-absolute"></div>
    </div>

    <div class="swiper-content">
      <div :class="[{ 'is-active': currentTab == 0 }, 'swiper__item', 'chart-content']">
        <div class="char-info">
          <div class="char-info__title">
            <div style="display: flex">
              <div class="min50">版本号：</div>
              <div class="pdr8">{{ baseInfo.version }}</div>
            </div>
            <div class="min100">
              <span :class="baseInfo.runStatus === '0' ? 'red' : 'green'">
                车辆状态：{{ baseInfo.runStatus === '0' ? '离线' : '在线' }}
              </span>
            </div>
          </div>
          <div class="char-info__title" v-if="baseInfo.isOverDate">
            <div class="min100">
              <span :class="baseInfo.isOverDate === '已到期' ? 'red' : 'green'">
                续费信息：{{ baseInfo.isOverDate }}
              </span>
            </div>
            <div>
              <span>过期时间：{{ baseInfo.renewalDueTime }}</span>
            </div>
          </div>
          <div class="char-info__title">
            <div>
              <span>系数：{{ baseInfo.versionString }}</span>
            </div>
          </div>
        </div>
        <div>
          <div id="trackContainer" style="height: 250px" v-if="chartData.allData.length > 2"></div>
          <div v-else style="line-height: 250px; text-align: center; height: 250px">未查询到数据</div>
        </div>
        <div>
          <chart-view height="200px" :chartOption="eChartOption"></chart-view>
        </div>
      </div>

      <div :class="[{ 'is-active': currentTab == 1 }, 'swiper__item', 'option-content']">
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item v-model="optimizeSort" :options="optimizeOptions" />
        </van-dropdown-menu>
        <div class="option-detail">
          <div v-if="optimizeSort === 0">
            <van-field
              label="问题开始时间:"
              label-width="100px"
              v-model="startTime_problem"
              readonly
              placeholder="请选择开始时间"
              @click-input="pickTime('startTime_problem')"
            />
            <van-field
              label="问题结束时间:"
              label-width="100px"
              v-model="endTime_problem"
              readonly
              placeholder="请选择结束时间"
              @click-input="pickTime('endTime_problem')"
            />

            <van-field
              label="问题类型:"
              label-width="100px"
              v-model="problemType"
              readonly
              placeholder="请选择问题类型"
              @click-input="pickProblemType"
            />

            <van-field
              v-if="problemType && problemType !== ''"
              label="问题分类:"
              label-width="100px"
              v-model="problemClass"
              readonly
              placeholder="请选择问题分类"
              @click-input="pickProblemClass"
            />

            <van-field
              v-if="problemClass === '传感器问题'"
              label="传感器现象:"
              label-width="100px"
              v-model="sensorPhenName"
              readonly
              placeholder="请选择传感器现象"
              @click-input="pickSensorName"
            />

            <van-field
              v-if="
                problemClass &&
                problemClass !== '' &&
                problemClass !== '其他' &&
                proSelectedItem.children[problemClassIndex]?.phenomenon
              "
              label="问题现象:"
              label-width="100px"
              v-model="phenomenon"
              readonly
              placeholder="请选择问题现象"
              @click-input="pickPhenomenon"
            />

            <van-field
              v-if="problemClass && problemClass === '其他'"
              v-model="phenomenonInput"
              label-width="100px"
              clearable
              label="问题现象:"
              placeholder="请填写问题现象"
            />

            <van-field
              v-if="
                phenomenon &&
                phenomenon !== '' &&
                proSelectedItem.children[problemClassIndex]?.phenomenon[phenomenonIndex]?.repairGuide
              "
              label="维修指导:"
              label-width="100px"
              v-model="repairGuide"
              readonly
              placeholder="请选择维修指导"
              @click-input="pickRepairGuide"
            />

            <van-field label="是否限制:" label-width="100px" readonly v-if="problemType === '硬件' && problemClass">
              <template #input>
                <div>
                  <van-switch v-model="isPush" size="20px" active-value="1" inactive-value="0" />
                </div>
              </template>
            </van-field>

            <div class="option_btn">
              <van-button block type="primary" @click="recordProb" :loading="recordLoading">问题记录</van-button>
              <van-button block type="primary" @click="submitDevice" v-if="problemTypeIndex === 1">设备故障</van-button>
            </div>
          </div>

          <div v-if="optimizeSort === 1">
            <van-field
              label="上货前置零:"
              label-width="100px"
              readonly
              placeholder="请选择上货前置零时间"
              v-model="zeroBeforeDate"
              @click-input="pickTime('zeroBeforeDate')"
            />

            <van-field
              label="上货开始:"
              label-width="100px"
              readonly
              placeholder="请选择上货开始时间"
              v-model="upStartDate"
              @click-input="pickTime('upStartDate')"
            />

            <van-field
              label="上货结束:"
              label-width="100px"
              readonly
              placeholder="请选择上货结束时间"
              v-model="upEndDate"
              @click-input="pickTime('upEndDate')"
            />

            <van-field
              label="卸货开始:"
              label-width="100px"
              readonly
              placeholder="请选择卸货开始时间"
              v-model="downStartDate"
              @click-input="pickTime('downStartDate')"
            />

            <van-field
              label="卸货结束:"
              label-width="100px"
              readonly
              placeholder="请选择卸货结束时间"
              v-model="downEndDate"
              @click-input="pickTime('downEndDate')"
            />

            <van-field
              label="卸货后置零:"
              label-width="100px"
              readonly
              placeholder="请选择卸货后置零时间"
              v-model="zeroAfterDate"
              @click-input="pickTime('zeroAfterDate')"
            />

            <van-field
              label="上货前重量:"
              label-width="100px"
              readonly
              placeholder="请输入上货前重量"
              v-model="startWeight"
              @click-input.stop="showNumInput('startWeight')"
            />

            <van-field
              label="重量:"
              label-width="100px"
              readonly
              placeholder="请输入重量"
              v-model="weight"
              @click-input.stop="showNumInput('weight')"
            />

            <van-field
              label="卸货后重量:"
              label-width="100px"
              readonly
              placeholder="请输入卸货后重量"
              v-model="endWeight"
              @click-input.stop="showNumInput('endWeight')"
            />

            <van-field label="数据波动:" label-width="100px">
              <template #input>
                <div class="input-switch">
                  <span>1吨内</span>
                  <van-switch v-model="isWave" size="20px" />
                </div>
              </template>
            </van-field>

            <van-field
              label="波动范围:"
              label-width="100px"
              readonly
              placeholder="波动范围"
              v-model="waveWeight"
              @click-input="wavePicker = true"
            />

            <van-field label="上卸货曲线:" label-width="100px" readonly placeholder="波动范围">
              <template #input>
                <div class="input-switch">
                  <span>斜线（默认是sin曲线）</span>
                  <van-switch v-model="isDiagonal" size="20px" />
                </div>
              </template>
            </van-field>

            <div class="option_btn">
              <van-button block type="primary" :disabled="isDisabled" @click="makeData" :loading="optiLoading">
                优化
              </van-button>
              <van-button block type="primary" @click="recordTransportData" :loading="recordTransportLoading">
                记录上卸货点
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择时间 -->
    <van-popup
      :show="datePickerShow"
      position="bottom"
      round
      style="height: '46%'"
      @click-overlay="datePickerShow = false"
    >
      <van-datetime-picker
        v-model="pickedDate"
        type="datetime"
        title="选择时间"
        @confirm="confirmDate"
        @cancel="datePickerShow = false"
      />
    </van-popup>
    <!-- 选择问题分类 -->
    <van-popup :show="problemPicker" @click-overlay="problemPicker = false" round position="bottom">
      <van-picker
        show-toolbar
        :columns="problemListColumns"
        @confirm="confirmProblemPick"
        @cancel="problemPicker = false"
      />
    </van-popup>

    <!-- 波动范围选择框 -->
    <van-popup :show="wavePicker" @click-overlay="wavePicker = false" round position="bottom">
      <van-picker show-toolbar :columns="waveListColumns" @confirm="confirmWavePick" @cancel="wavePicker = false" />
    </van-popup>
    <van-number-keyboard
      :show="numKeyShow"
      theme="custom"
      extra-key="."
      close-button-text="完成"
      @blur="numKeyShow = false"
      @input="onNumInput"
      @delete="onNumDelete"
    />
  </div>
</template>
<script>
import { onMounted, toRefs } from 'vue'
import { useStore } from 'vuex'
import ChartView from '@/components/echart/index.vue'
import optimizeChart from './optimizeChart'
import optimizeData from './optimizeData'

export default {
  components: {
    ChartView
  },
  setup() {
    const store = useStore()

    const { constants, state, problemListColumns, problemState, dataState, setTransfer, tabClick, pickFuncs } =
      optimizeData()

    const deviceId = store.state.optimize.deviceId
    const startTime = store.state.optimize.startTime
    const endTime = store.state.optimize.endTime
    const chartModule = optimizeChart()
    const { getData, baseInfo, eChartOption, state: chartData } = chartModule

    onMounted(() => {
      // 默认选中第一个tab
      setTransfer(0)
      // 默认显示选择问题类型
      getData({ deviceId, startTime, endTime })
    })

    return {
      eChartOption,
      chartData,
      ...constants,
      ...toRefs(state),
      ...toRefs(problemState),
      ...toRefs(dataState),
      tabClick,
      problemListColumns,
      ...pickFuncs,
      baseInfo
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
.optimize-tab-list {
  display: flex;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  position: relative;
  background-color: #fff;
  padding-bottom: 10px;
  .optimize-tab {
    flex: 1;
    text-align: center;
    &-active {
      color: $blue;
    }
  }
  .optimize-tabs__line {
    bottom: 10px;
    left: 0;
    z-index: 1;
    width: 60px;
    height: 2px;
    background: $blue;
    border-radius: 2px;
    transition: transform 0.3s ease-in-out;
  }
}
.optimize-wrapper {
  position: relative;
  color: #9b9a9a;
  .chart-content,
  .option-content {
    background: #fff;
    .option-detail {
      .van-field {
        font-size: 12px;
      }
    }
  }
  .char-info {
    color: #333;
    padding: 12px;
    font-size: 10px;
    &__title {
      display: flex;
      justify-content: space-between;
    }
    div {
      margin-bottom: 4px;
    }
  }
  .option_btn {
    padding: 8px 12px;
    button {
      margin-bottom: 8px;
    }
  }
}
.input-switch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
}
</style>
