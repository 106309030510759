<template>
  <NavBar />
  <div class="container">
    <van-cell-group>
      <van-field label="接口类型" v-model="interfaceType" readonly @click="isPickType = true" />
      <van-field label="公司名称" v-model="companyName" readonly placeholder="请选择公司名称" @click="selectCompany" />
      <van-field label="车牌号码" v-model="carNumber" readonly placeholder="请选择车牌号码" @click="selectCar" />
      <van-field label="设备ID" v-model="deviceId" readonly />
      <van-field label="起始时间" v-model="startTime" readonly @click="pickTime(true)" />
      <van-field label="截止时间" v-model="endTime" readonly @click="pickTime(false)" />
    </van-cell-group>
    <div style="margin: 16px">
      <van-button round block type="primary" @click="printChart">数据绘图</van-button>
    </div>
  </div>
  <van-popup :show="isPickType" @click-overlay="isPickType = false" round position="bottom">
    <van-picker
      title="选择接口类型"
      show-toolbar
      :columns="typeList"
      @confirm="confirmType"
      @cancel="isPickType = false"
    />
  </van-popup>

  <van-popup :show="isPickTime" @click-overlay="isPickTime = false" round position="bottom">
    <van-datetime-picker
      v-model="timePicked"
      type="datetime"
      title="选择时间"
      @confirm="confirmDate"
      @cancel="isPickTime = false"
    />
  </van-popup>
</template>
<script>
import { defineComponent, reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import moment from 'moment'
import { Toast } from 'vant'
import { correctTimeParams, formatTimeStr } from '@/utils/common'

const typeList = [
  { text: '平台数据接口', value: '1' },
  { text: 'API数据接口', value: '2' }
]

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const current = new Date()
    const storeData = store.state.optimize
    const state = reactive({
      interfaceType: '平台数据接口',
      apiType: '1',
      companyName: computed(() => storeData.companyName),
      companyId: computed(() => storeData.companyId),
      carNumber: computed(() => storeData.carNumber),
      deviceId: computed(() => storeData.deviceId),
      startTime: '',
      endTime: ''
    })
    state.startTime = storeData.startTime || formatTimeStr(new Date(current.getTime() - 1000 * 60 * 60 * 24))
    state.endTime = storeData.endTime || formatTimeStr(current)

    const popState = reactive({
      isPickType: false,
      isPickTime: false,
      timePicked: current,
      isStart: true
    })

    const selectCompany = () => {
      router.push({
        name: 'SelectCompany',
        params: {
          section: 'optimize'
        }
      })
    }

    const selectCar = () => {
      router.push({
        name: 'OptimizeSearchCar'
      })
    }

    const confirmType = val => {
      state.interfaceType = val.text
      state.apiType = val.value
      popState.isPickType = false
    }

    const pickTime = isStart => {
      popState.isStart = isStart
      if (isStart) {
        popState.timePicked = moment(state.startTime).toDate()
      } else {
        popState.timePicked = moment(state.endTime).toDate()
      }
      popState.isPickTime = true
    }

    const confirmDate = date => {
      if (popState.isStart) {
        state.startTime = formatTimeStr(date)
      } else {
        state.endTime = formatTimeStr(date)
      }
      popState.isPickTime = false
    }
    const printChart = () => {
      if (!state.carNumber || !state.deviceId) {
        Toast('请选择查询的车辆信息')
        return
      }
      let timeCheck = correctTimeParams(state.startTime, state.endTime, 31)
      if (!timeCheck.status) {
        Toast(timeCheck.message)
        return
      }
      store.commit('optimize/updateState', state)
      const title = `数据优化-${state.carNumber}`
      router.push({
        name: 'OptimizePrint',
        query: {
          title
        }
      })
    }
    return {
      typeList,
      ...toRefs(state),
      ...toRefs(popState),
      selectCompany,
      confirmType,
      pickTime,
      confirmDate,
      selectCar,
      printChart
    }
  }
})
</script>
