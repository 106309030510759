<template>
  <div class="card list-item">
    <van-row>
      <van-col span="12">{{ data.carNumber }}</van-col>
      <van-col span="12">
        <span>
          问题来源:
          <span>{{ data.source === 1 ? '客户投诉' : '平台发现' }}</span>
        </span>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="24">
        <span>
          厂区:
          <span>{{ data.companyName }}</span>
        </span>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="24" style="display: flex; justify-content: space-between">
        <span>
          订单时间:
          <span>{{ data.auditTime }}</span>
        </span>
        <span :class="showStatus(data.status, 'className', 'red')">
          {{ showStatus(data.status, 'text', '审核不通过') }}
        </span>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="24" style="display: flex; justify-content: space-between">
        <span>
          处理原因:
          <span>{{ data.handleReason }}</span>
        </span>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup() {
    // if (el.status === 0) {
    //   el.status = '待审核'
    //   el.statusClass = 'orange'
    // } else if (el.status === 1) {
    //   el.status = '审核通过'
    //   el.statusClass = 'green'
    // } else {
    //   el.status = '审核不通过'
    //   el.statusClass = 'red'
    // }
    const statusList = [
      {
        value: 0,
        text: '待审核',
        className: 'orange'
      },
      {
        value: 1,
        text: '审核通过',
        className: 'green'
      }
    ]

    const showStatus = (val, key, defaultVal) => {
      const item = statusList.find(el => el.value === val)
      return item ? item[key] : defaultVal
    }

    return {
      showStatus
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  height: 100px;
  overflow-y: hidden;
  font-size: 12px;
  padding: 12px;
  color: #455a6499;
  font-weight: normal;
  line-height: 18px;
}
</style>
