<template>
  <NavBar>
    <template #right>
      <div class="icon-area">
        <van-icon name="search" @click="queryOptions" />
      </div>
    </template>
  </NavBar>
  <PullList ref="listRef" :fetchFn="getOrderList" @select="checkDetail" :params="queryParams">
    <template #default="{ data }">
      <Card :data="data" />
    </template>
  </PullList>

  <router-view v-slot="{ Component }">
    <transition :name="animationName">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import { onMounted, ref, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

import PullList from '@/views/components/PullList.vue'

import Card from './components/card.vue'

import { http_clean } from '@/utils/request'
import { useAnimation } from '@/hooks/useAnimation'
export default {
  name: 'GPSAudit',
  components: {
    PullList,
    Card
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const { animationName } = useAnimation()

    const queryParams = computed(() => {
      const { carNumber, searchKey, source, startTime, endTime } = store.state.gpsAudit
      return {
        carNumber,
        searchKey,
        source,
        startTime,
        endTime
      }
    })
    const listRef = ref(null)

    const route = useRoute()

    watch(
      () => route.fullPath,
      (current, prev) => {
        if (prev === '/settings/gps-query' || prev === '/settings/gps-audit/audit-detail') {
          listRef.value.onRefresh()
        }
      }
    )

    onMounted(() => {
      listRef.value.onRefresh()
    })

    const getOrderList = async gridPager => {
      const data = await http_clean({
        url: 'projectCompany/gpsAuditList.html',
        method: 'post',
        data: { gridPager }
      })

      return data
    }

    // 跳转查询页面
    const queryOptions = () => {
      router.push({
        name: 'GpsQueryOptions'
      })
    }

    const checkDetail = data => {
      store.commit('gpsAudit/updateCheckData', data)
      router.push({
        name: 'GPSAuditDetail'
      })
    }
    return { listRef, animationName, queryParams, getOrderList, queryOptions, checkDetail }
  }
}
</script>
<style lang="scss" scoped></style>
