<template>
  <div class="tabs">
    <div @click="selectStatus('1')" :class="['item', { 'item-active': status === '1' }]">
      <span>待审核({{ notAuditCount }})</span>
    </div>
    <div @click="selectStatus('2')" :class="['item', { 'item-active': status == '2' }]">
      <span>已审核({{ auditedCount }})</span>
    </div>
    <div @click="selectStatus('')" :class="['item', { 'item-active': status == '' }]">
      <span>全部({{ notAuditCount + auditedCount }})</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    },
    notAuditCount: {
      type: Number,
      required: true
    },
    auditedCount: {
      type: Number,
      required: true
    }
  },
  emits: ['select'],
  setup(props, { emit }) {
    const selectStatus = val => {
      emit('select', val)
    }

    return {
      selectStatus
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
.tabs {
  display: flex;
  color: #323233;
  background-color: #fff;
  .item {
    box-sizing: border-box;
    flex: 1;
    text-align: center;
    height: 36px;
    line-height: 36px;
    &-active {
      color: $blue;
    }
  }
}
</style>
