<template>
  <div class="scroll-y flex-main" id="pull-list" @scroll="scrollHandler" ref="scrollEl">
    <van-pull-refresh v-model="state.pullLoading" @refresh="onRefresh" class="pull-wrapper">
      <van-list
        v-model:loading="state.listLoading"
        :finished="state.finished"
        finished-text="没有更多了"
        v-model:error="state.error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
        class="list-wrap"
      >
        <div class="list-wrap__item" v-for="item in listData" :key="item[uniqueKey]" @click="$emit('select', item)">
          <slot :data="item" />
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="affix" v-if="listData.length > 0">
      <span>
        {{ pager.currentPage }}
      </span>
      <span>/</span>
      <span>
        {{ pager.pageCount }}
      </span>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { to } from '@/utils/common'
export default {
  props: {
    uniqueKey: {
      type: String,
      default: 'id'
    },
    // 请求参数对象
    params: {
      type: Object,
      default: () => ({})
    },
    // 请求方法，会将页脚信息和传入的 params 参数格式化作为参数传入
    fetchFn: {
      type: Function,
      required: true
    }
  },
  emits: ['select'],
  setup(props) {
    const scrollEl = ref(null)
    const listData = ref([]) // 列表数据

    const state = reactive({
      pullLoading: false, // 下拉刷新状态
      listLoading: false, // list 加载状态
      finished: true, // 是否已经全部完成，默认完成，手动加载第一次
      error: false // 列表加载错误
    })
    const pager = reactive({
      queryPage: 1, // 需要查询的页数
      currentPage: 1, // 当前滚动内容所属页
      pageCount: 1 // 总页数，接口返回
    })

    // 挂载后自动加载第一页信息
    // onMounted(() => {
    //   queryOrder()
    // })

    const scrollHandler = e => {
      let distance = e.target.scrollTop + e.target.getBoundingClientRect().height
      const itemEl = document.querySelector('#pull-list .list-wrap__item')
      // dom 高度
      const innerHeigh = itemEl.getBoundingClientRect().height
      // 获取css属性
      const cssComAttrs = document.defaultView.getComputedStyle(itemEl)
      const marginT = parseFloat(cssComAttrs.marginTop)
      const elHeight = innerHeigh + marginT
      // 每页10条数据，页数计算向上取整
      // 超出最大值，显示最大值
      pager.currentPage =
        Math.ceil(distance / (elHeight * 10)) >= pager.pageCount
          ? pager.pageCount
          : Math.ceil(distance / (elHeight * 10))
      if (pager.currentPage < 1) pager.currentPage = 1
    }

    // 下拉刷新
    const onRefresh = () => {
      state.pullLoading = true
      pager.queryPage = 1
      scrollEl.value.scrollTop = 0
      queryOrder()
    }

    // list的加载事件，页面一进入，默认会执行一次
    const onLoad = () => {
      // 是否全部加载完成
      if (!state.finished) {
        pager.queryPage++
        queryOrder()
      }
    }

    // 统一处理相同参数，并将参数回传给外外部请求方法
    async function queryOrder() {
      const gridPager = JSON.stringify({
        pageSize: '10',
        nowPage: `${pager.queryPage}`,
        parameters: props.params
      })
      const [err, data] = await to(props.fetchFn(gridPager))
      if (err) {
        pager.queryPage--
        state.pullLoading = false
        state.listLoading = false
        state.error = true
        return
      }
      if (!data.isSuccess) {
        // 加载失败需要重新请求当前页
        pager.queryPage--
        state.pullLoading = false
        state.listLoading = false
        state.finished = false
        state.error = true
        return
      }
      data.exhibitDatas = data.isSuccess ? data.exhibitDatas : []
      setTimeout(() => {
        if (state.pullLoading) {
          listData.value = []
        }
        listData.value = listData.value.concat(data.exhibitDatas)
        pager.pageCount = data.pageCount
        // 判断是否全部加载完成
        state.finished = pager.queryPage >= pager.pageCount
        state.pullLoading = false
        state.listLoading = false
      }, 200)
    }

    return {
      state,
      listData,
      pager,
      scrollEl,
      onRefresh,
      onLoad,
      scrollHandler
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
// 图钉页脚
.affix {
  box-sizing: border-box;
  padding: 0 16px;
  border-radius: 8px 0 0 8px;
  overflow: hidden;
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 36px;
  background-color: #f1f1f1;
}
</style>
