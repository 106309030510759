<template>
  <div class="cover-page">
    <NavBar />
    <div class="container">
      <div class="card__content" v-for="item in orderList" :key="item.index">
        <div class="card__row detail">
          <div class="detail__cost detail__item">
            <div>
              {{ item.costDetail }}
            </div>
          </div>
          <van-checkbox-group v-model="item.priceTypeArr" class="detail__price" :disabled="isPass" :max="1">
            <van-checkbox
              :name="1"
              @click="changPrice(item, 1)"
              class="detail__item"
              style="height: 50%; border-bottom: 1px solid #323233"
            >
              <span>原价：</span>
              <span>{{ item.price }}</span>
              元
              <span v-if="item.unit">/{{ item.unit }}</span>
            </van-checkbox>
            <van-checkbox
              :name="2"
              class="detail__item"
              style="height: 50%"
              @click="changPrice(item, 2)"
              :disabled="item.discount == 0"
            >
              <span>减免价：</span>
              <span>{{ item.discountPrice }}</span>
              元
              <span v-if="item.unit">/{{ item.unit }}</span>
            </van-checkbox>
          </van-checkbox-group>
          <div class="detail__count detail__item">
            <div>
              <van-stepper
                integer
                max="999999999"
                v-model="item.count"
                input-width="20px"
                button-size="16px"
                :disabled="item.priceType == 0 || item.restrict == 0"
                :min="item.priceType == 0 || item.restrict == 0 ? 0 : 1"
                @change="calcData(item)"
              />
            </div>
            <span>次</span>
          </div>
          <div class="detail__total detail__item">
            <div>
              {{ item.totalPrice }}
              <span>元</span>
            </div>
          </div>
        </div>

        <div class="card__row remark">
          <div><span>备注:</span></div>
          <textarea
            v-model="item.remark"
            class="remark-content"
            :placeholder="item.priceType == 2 ? '请输入减免原因' : ''"
          />
        </div>
      </div>

      <div class="sum-up">
        <div>
          <span v-if="payStatus == 1">待支付</span>
          <span v-if="payStatus == 2">已支付</span>
          <span v-if="payStatus == 3">订单取消</span>
          <span v-if="payStatus == 4">已完成</span>
        </div>
        <div>
          <span>{{ totalCost }}元</span>
        </div>
      </div>
      <div v-if="!isPass" class="audit-section">
        <span>提示:通过审核后，小程序内将生成“待付款”维修订单</span>
        <van-button type="primary" @click="submitAudit">通过审核</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Toast } from 'vant'
import { deepClone } from '@/utils/common'
import http from '@/utils/request'

const initialOrderList = [
  {
    costDetail: '移机',
    priceTypeArr: [''],
    price: 0,
    discount: 1,
    discountPrice: 0,
    priceType: 0
  },
  {
    costDetail: '更换-传感器',
    priceTypeArr: [''],
    price: 0,
    discount: 1,
    discountPrice: 0,
    priceType: 0
  },
  {
    costDetail: '更换-天线',
    priceTypeArr: [''],
    price: 0,
    discount: 1,
    discountPrice: 0,
    priceType: 0
  },
  {
    costDetail: '维修工时',
    priceTypeArr: [''],
    price: 0,
    discount: 1,
    discountPrice: 0,
    priceType: 0
  },
  {
    costDetail: '其他',
    priceTypeArr: [''],
    price: 0,
    discount: 1,
    discountPrice: 0,
    priceType: 0
  }
]

export default defineComponent({
  name: 'MaintainOrderDetail',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      orderList: initialOrderList,
      totalCost: 0,
      payStatus: 1,
      isPass: false
    })

    state.payStatus = route.query.payStatus
    state.isPass = route.query.auditStatus == 2
    onMounted(() => {
      http({
        url: 'repairOrder/getRepairNameById.html',
        method: 'post',
        data: {
          id: route.query.id
        }
      }).then(res => {
        if (res.success) {
          const { data } = res
          let result = 0
          for (let i = 0; i < data.length; i++) {
            if (data[i].priceType == 0) {
              data[i].totalPrice = 0
              result += 0
            } else if (data[i].priceType == 1) {
              data[i].totalPrice = (data[i].price * data[i].count) / 100
              result += data[i].price * data[i].count
            } else if (data[i].priceType == 2) {
              data[i].totalPrice = (data[i].discountPrice * data[i].count) / 100
              result += data[i].discountPrice * data[i].count
            }
            data[i].price = data[i].price / 100
            data[i].discountPrice = data[i].discountPrice / 100
            data[i].priceTypeArr = [data[i].priceType]
          }
          state.totalCost = (result / 100).toFixed(2)
          state.orderList = data
        }
      })
    })

    const submitAudit = () => {
      const repairOrderList = deepClone(state.orderList)
      const total = state.totalCost * 100
      const id = route.query.id
      const repairOrder = []
      for (let i = 0; i < repairOrderList.length; i++) {
        repairOrderList[i].totalPrice = repairOrderList[i].totalPrice * 100
        repairOrderList[i].price = repairOrderList[i].price * 100
        repairOrderList[i].discountPrice = repairOrderList[i].discountPrice * 100
        if (repairOrderList[i].priceType == 2 && !repairOrderList[i].remark) {
          Toast('请输入减免原因')
          return
        }
        if (repairOrderList[i].priceType != 0) {
          repairOrder.push(repairOrderList[i])
        }
      }

      if (repairOrder.length == 0) {
        Toast('至少需要选择一项任何维修方案')
        return
      }
      http({
        url: 'repairOrder/updateOrderById.html',
        method: 'post',
        data: {
          id,
          total,
          repairOrder: JSON.stringify(repairOrder)
        }
      }).then(data => {
        Toast(data.message)
        if (data.success) {
          setTimeout(() => {
            router.go(-1)
          }, 500)
        }
      })
    }
    const changPrice = (item, key) => {
      if (item.discount == 0 && key == 2) return
      if (item.priceType !== key) {
        item.priceType = key
        item.priceTypeArr = [key]
      } else {
        item.priceType = 0
      }
      item.count = 1
      if (item.priceType === 1) {
        item.totalPrice = item.price * item.count
      } else if (item.priceType === 2) {
        item.totalPrice = item.discountPrice * item.count
      } else {
        item.count = 0
        item.totalPrice = 0
      }
      const sum = state.orderList.reduce((acc, current) => acc + Number(current.totalPrice) * 100, 0)
      state.totalCost = (sum / 100).toFixed(2)
    }
    const calcData = item => {
      if (item.priceType === 1) {
        item.totalPrice = item.price * item.count
      } else if (item.priceType === 2) {
        item.totalPrice = item.discountPrice * item.count
      } else {
        item.count = 0
        item.totalPrice = 0
      }
      const sum = state.orderList.reduce((acc, current) => acc + Number(current.totalPrice) * 100, 0)
      state.totalCost = (sum / 100).toFixed(2)
    }
    return {
      ...toRefs(state),
      submitAudit,
      changPrice,
      calcData
    }
  }
})
</script>
<style lang="scss" scoped>
.card__content {
  border: 1px solid #323233;
  border-radius: 8px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  margin: 10px 4px;
  font-size: 10px;
  .detail {
    height: 60px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #323233;

    &__cost {
      width: 74px;
      height: 100%;
      border-right: 1px solid #323233;
    }
    &__price {
      width: 140px;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #323233;
    }
    &__count {
      width: 74px;
      border-right: 1px solid #323233;
    }
    &__total {
      width: 74px;
      word-break: break-all;
      text-align: center;
    }
    &__item {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .remark {
    padding: 0 8px;
    display: flex;
    height: 32px;
    line-height: 24px;
    .remark-content {
      flex: 1;
      border: none;
      background-color: transparent;
      font-size: 10px;
      line-height: 24px;
      padding: 0 8px;
      &::placeholder {
        color: red;
      }
    }
  }
}
.sum-up {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}
.audit-section {
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
  color: #323233;
  button {
    width: 100%;
    height: 30px;
    margin-top: 8px;
  }
}
</style>
