<template>
  <ChartView height="250px" :chartOption="chartOption" />
  <div id="track-container" style="height: 250px"></div>
</template>

<script>
import { reactive, onMounted, onBeforeUnmount } from 'vue'
import { Toast } from 'vant'

import startIcon from '@/assets/bMap/icon_stsrt.png'
import endIcon from '@/assets/bMap/icon_end.png'
import carIcon from '@/assets/bMap/map-indicator-60x60.png'

import ChartView from '@/components/echart/index.vue'

import { toBDMapPointArr, toBDMapPoint } from '@/utils/mapUtil'
import { http_clean } from '@/utils/request'
import { formatTimeStr, getMilliSecond, getTimeDiff, lineGetColor } from '@/utils/common'
import { yAxiSignal, yAxiWeight } from '@/utils/signalChartConst'

const yAxiSpeed = {
  show: true,
  name: 'km/h',
  splitLine: {
    show: false
  },
  type: 'value',
  min: undefined,
  max: undefined,
  offset: 60,
  position: 'right',
  nameTextStyle: {
    align: 'left'
  },
  axisLine: {
    lineStyle: {
      color: '#5793f3'
    }
  },
  axisLabel: {
    formatter: '{value}'
  }
}

export default {
  components: { ChartView },
  props: {
    deviceId: {
      type: String,
      required: true
    },
    dateList: {
      type: Array,
      required: true
    },
    showModify: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { expose }) {
    let mapInstance = null

    const printData = {
      allData: [], // 折线图需要的所有数据
      linetoMapData: [], //地图联动画轨迹数据
      timeShadow: [], // 补全时间数据
      loadometerData: [], // 地磅chart的配置项
      loadometerArr: [], // 地磅的数据
      nullGpsArr: [], //经纬度空
      timeShadowList: [], //进入围栏在echart显示的阴影区域
      cordonLineMarkArea: [],
      allChartline: [], // 折线图需要的所有配置项
      offlineAbParams: [],
      legendSleced: {},
      averageTime: 0, //默认判断丢失数据的时间
      passageName: [],
      passageArr: [],
      carSpeedArr: [], // 车辆速度
      yAxiWeight: yAxiWeight,
      yAxiSpeed: yAxiSpeed,
      yAxiSignal: yAxiSignal
    }

    const chartOption = reactive({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross' //十字星
        },
        formatter: function (params) {
          let res = ''
          const len = params.length
          for (let i = 0; i < len; i++) {
            res = res + params[i].marker + params[i].seriesName + ':' + params[i].data + '</br>'
          }
          const paramIndex = params[0].dataIndex
          const pointObj = printData.linetoMapData[paramIndex]
          if (pointObj.lat && pointObj.lng) {
            resetMoveInfoBox(pointObj)
          }
          return res
        }
      },
      grid: {
        left: '8%',
        right: '24%',
        top: '40%',
        bottom: '10%'
      },
      toolbox: {
        feature: {
          dataView: { show: false, readOnly: false },
          restore: { show: false },
          saveAsImage: { show: false }
        }
      },
      legend: {
        selected: {}
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
      },
      yAxis: [yAxiWeight],

      series: [
        {
          name: '重量',
          type: 'line',
          data: []
        }
      ]
    })

    let movingMarker = null // 地图上的车辆标记
    //移动折线图联动地图移动点
    function resetMoveInfoBox(params) {
      const point = new BMapGL.Point(params.lng, params.lat)
      const icon = new BMapGL.Icon(carIcon, new BMapGL.Size(60, 60), {
        imageSize: new BMapGL.Size(60, 60)
      })
      if (!movingMarker) {
        movingMarker = new BMapGL.Marker(point, {
          icon: icon
        })
        mapInstance.addOverlay(movingMarker)
      }
      movingMarker.setPosition(point)
    }

    onMounted(() => {
      mapInstance = new BMapGL.Map('track-container')
      mapInstance.enableScrollWheelZoom(true)
      mapInstance.addControl(new BMapGL.ZoomControl())
      mapInstance.addControl(new BMapGL.ScaleControl())
      const point = new BMapGL.Point(116.404, 39.915)
      // 创建点坐标
      mapInstance.centerAndZoom(point, 15)
      mapInstance.setDisplayOptions({
        poiIcon: false,
        building: false //是否显示3D建筑物
      })
    })

    onBeforeUnmount(() => {
      mapInstance && mapInstance.destroy()
      mapInstance = null
    })

    // 保存阴影标记数据
    const { timeShadowList, cordonLineMarkArea } = formatMark(props.dateList)
    printData.timeShadowList = timeShadowList
    printData.cordonLineMarkArea = cordonLineMarkArea

    function askForData(startTime, endTime) {
      if (!props.deviceId) {
        Toast('设备编号不能为空')
        return
      }
      printData.allData = []
      printData.linetoMapData = []
      printData.timeShadow = []
      printData.loadometerData = []
      const reqdata = {
        deviceId: props.deviceId,
        showModify: props.showModify,
        startTime,
        endTime
      }
      http_clean({
        url: 'weighbridge/weightforreal.html',
        method: 'post',
        data: reqdata
      }).then(result => {
        if (result.data.length >= 1) {
          printData.loadometerData = result.data
        }
      })
      http_clean({
        url: 'stats/draw.html',
        method: 'post',
        data: reqdata
      }).then(data => {
        if (data.data.length < 2) {
          Toast('没有数据')
          return
        }
        printData.allData = data.data
        /**
         * 对筛选时间范围内,返回的历史数据做处理,对于数据缺失的时间段进行数据的补点,在echart对应显示灰色区域,表明该时间段范围内没有历史数据.
         */
        //判断上货时间是否在搜索开始时间之后
        if (getTimeDiff(printData.allData[0].uploadDate, startTime) > 150 * 1000) {
          // 默认没有上货操作
          let hasWeightInEmptyDataStartTime = false
          for (let i = 0; i < printData.loadometerData.length; i++) {
            let item = printData.loadometerData[i]
            // 如果装货时间在搜索范围内有地磅数据，需要画点
            if (
              getMilliSecond(startTime) < getMilliSecond(item.date) &&
              getMilliSecond(item.date) < getMilliSecond(printData.allData[0].uploadDate)
            ) {
              hasWeightInEmptyDataStartTime = true
              break
            }
          }
          if (hasWeightInEmptyDataStartTime) {
            const emptyDataStartTime = [] //存放搜索开始时间空数据补点的集合
            for (
              let t = getMilliSecond(startTime);
              t < getMilliSecond(printData.allData[0].uploadDate);
              t = t + 150 * 1000
            ) {
              const temDate = formatTimeStr(new Date(t))
              const temObj = {
                aCH1: '',
                aCH2: '',
                aCH3: '',
                aCH4: '',
                aCH5: '',
                aCH6: '',
                aCH7: '',
                aCH8: '',
                aCH9: '',
                aCH10: '',
                aCH11: '',
                aCH12: '',
                aCH13: '',
                aCH14: '',
                aCH15: '',
                aCH16: '',
                checkGps: '',
                checkWeight: '',
                deviceId: props.deviceId, // 设备id
                handBrakeHardwareStatus: '',
                handBrakeSoftwareStatus: '',
                mWeight: '',
                rectifyWeight: '',
                rectifyX: '',
                rectifyY: '',
                speed: '',
                uploadDate: temDate,
                weight: '',
                x: printData.allData[0].x,
                y: printData.allData[0].y
              }
              emptyDataStartTime.push(temObj)
            }

            const emptyShadowStart = [
              {
                name: '无数据',
                itemStyle: {
                  color: '#ddd',
                  opacity: 0.3
                },
                label: {
                  color: '#333',
                  fontSize: '16',
                  position: ['50%', '50%'],
                  offset: [-24, -8]
                },
                emphasis: {
                  itemStyle: {
                    color: '#ddd',
                    opacity: 0.5
                  },
                  label: {
                    position: ['50%', '50%'],
                    offset: [-24, -8]
                  }
                },
                xAxis: startTime
              },
              {
                xAxis: printData.allData[0].uploadDate
              }
            ]
            printData.timeShadow.push(emptyShadowStart)
            printData.allData = emptyDataStartTime.concat(printData.allData)
          }
        }
        // 同理，判断上货时间是否在搜索结束之前
        if (getTimeDiff(endTime, printData.allData[printData.allData.length - 1].uploadDate) > 150 * 1000) {
          // 判断曲线数据后有没有过磅数据,有过磅数据,显示无数据区域,没有过磅数据则不显示无数据区域
          let hasWeightInEmptyDataEndTime = false
          for (let i = 0; i < printData.loadometerData.length; i++) {
            let item = printData.loadometerData[i]
            if (
              getMilliSecond(printData.allData[printData.allData.length - 1].uploadDate) < getMilliSecond(item.date) &&
              getMilliSecond(item.date) < getMilliSecond(endTime)
            ) {
              hasWeightInEmptyDataEndTime = true
              break
            }
          }
          if (hasWeightInEmptyDataEndTime) {
            let emptyDataEndTime = [] //存放搜索结束时间空数据补点的集合
            let EndTime = ''
            if (getTimeDiff(new Date(), endTime) > 0) {
              EndTime = endTime
            } else {
              EndTime = formatTimeStr(new Date())
            }
            for (
              let t = getMilliSecond(printData.allData[printData.allData.length - 1].uploadDate) + 150 * 1000;
              t < getMilliSecond(EndTime);
              t = t + 150 * 1000
            ) {
              let temDate = formatTimeStr(new Date(t))
              let temObj = {
                aCH1: '',
                aCH2: '',
                aCH3: '',
                aCH4: '',
                aCH5: '',
                aCH6: '',
                aCH7: '',
                aCH8: '',
                aCH9: '',
                aCH10: '',
                aCH11: '',
                aCH12: '',
                aCH13: '',
                aCH14: '',
                aCH15: '',
                aCH16: '',
                checkGps: '',
                checkWeight: '',
                deviceId: props.deviceId,
                handBrakeHardwareStatus: '',
                handBrakeSoftwareStatus: '',
                mWeight: '',
                rectifyWeight: '',
                rectifyX: '',
                rectifyY: '',
                speed: '',
                uploadDate: temDate,
                weight: '',
                x: printData.allData[printData.allData.length - 1].x,
                y: printData.allData[printData.allData.length - 1].y
              }
              emptyDataEndTime.push(temObj)
            }
            let emptyShadowEnd = [
              {
                name: '无数据',
                itemStyle: {
                  color: '#ddd',
                  opacity: 0.5
                },
                label: {
                  color: '#333',
                  fontSize: '16',
                  position: ['50%', '50%'],
                  offset: [-24, -8]
                },
                emphasis: {
                  itemStyle: {
                    color: '#ddd',
                    opacity: 0.5
                  },
                  label: {
                    position: ['50%', '50%'],
                    offset: [-24, -8]
                  }
                },
                xAxis: printData.allData[printData.allData.length - 1].uploadDate
              },
              {
                xAxis: emptyDataEndTime[emptyDataEndTime.length - 1].uploadDate
              }
            ]
            printData.timeShadow.push(emptyShadowEnd)
            printData.allData = printData.allData.concat(emptyDataEndTime)
          }
        }
        // 转换坐标
        printData.allData.forEach((el, i) => {
          const mapPoint = toBDMapPoint(el)
          if (
            mapPoint &&
            mapPoint.lng &&
            mapPoint.lat &&
            mapPoint.lng !== '0.0' &&
            mapPoint.lat !== '0.0' &&
            mapPoint.lat != '0' &&
            mapPoint.lng != '0'
          ) {
            printData.linetoMapData[i] = mapPoint
          }
        })
        queryHistory(printData.allData, mapInstance)
        getOffData(reqdata).then(() => {
          coupudChartDatas()
        })
      })
    }
    //计算折线图的数据
    function coupudChartDatas() {
      const weightArr = []
      let timeValue = []
      let len = printData.allData.length
      printData.averageTime = getAverageTime() //计算数据丢失的过滤时间阈值
      for (let i = 0; i < len; i++) {
        timeValue[i] = printData.allData[i].uploadDate //x时间轴数据
        weightArr[i] = parseFloat(printData.allData[i].weight) //重量
        if (weightArr[i] < 0 && weightArr[i] >= -1) {
          weightArr[i] = 0
        }
        let _speed = parseFloat(printData.allData[i].speed) //速度
        if (_speed > -1) {
          printData.carSpeedArr[i] = parseFloat(printData.allData[i].speed)
        } else {
          printData.carSpeedArr[i] = 0
        }
        printData.loadometerArr[i] = '-' //地磅数据
        printData.nullGpsArr[i] = '-' //经纬度空
      }

      //警戒线
      if (!printData.timeShadowList) printData.timeShadowList = []
      let timeShadowList = printData.timeShadowList
      if (!printData.cordonLineMarkArea) printData.cordonLineMarkArea = []
      let cordonLineMarkArea = printData.cordonLineMarkArea
      //首先在横坐标找出某个点, 时间上相同（忽略秒），使用该点的横坐标
      for (let j = 0; j < timeShadowList.length; j++) {
        for (let k = 0; k < printData.allData.length; k++) {
          if (
            printData.allData[k].uploadDate.substring(0, printData.allData[k].uploadDate.length - 3) ==
            timeShadowList[j].xAxis.substring(0, timeShadowList[j].xAxis.length - 3)
          ) {
            if (printData.allData[k].uploadDate != timeShadowList[j].xAxis) {
              timeShadowList[j].xAxis = printData.allData[k].uploadDate
            }
          }
        }
      }

      //首先在横坐标找出某个点, 时间上相同（忽略秒），使用该点的横坐标
      for (let a = 0; a < cordonLineMarkArea.length; a++) {
        for (let b = 0; b < printData.allData.length; b++) {
          if (
            printData.allData[b].uploadDate.substring(0, printData.allData[b].uploadDate.length - 3) ==
            cordonLineMarkArea[a][0].xAxis.substring(0, cordonLineMarkArea[a][0].xAxis.length - 3)
          ) {
            if (printData.allData[b].uploadDate != cordonLineMarkArea[a][0].xAxis) {
              cordonLineMarkArea[a][0].xAxis = printData.allData[b].uploadDate
            }
          }
          if (
            printData.allData[b].uploadDate.substring(0, printData.allData[b].uploadDate.length - 3) ==
            cordonLineMarkArea[a][1].xAxis.substring(0, cordonLineMarkArea[a][1].xAxis.length - 3)
          ) {
            if (printData.allData[b].uploadDate != cordonLineMarkArea[a][1].xAxis) {
              cordonLineMarkArea[a][1].xAxis = printData.allData[b].uploadDate
            }
          }
        }
      }

      printData.allChartline = []
      const weightLine = {
        name: '重量（吨）',
        type: 'line',
        borderWidth: 5,
        yAxisIndex: 0,
        data: weightArr,
        itemStyle: {
          color: '#c23531'
        }
      }
      if (props.showModify) {
        weightLine.markArea = {
          itemStyle: {
            color: '#ff00ff',
            opacity: 0.2
          },
          data: cordonLineMarkArea
        }
        weightLine.markLine = {
          lineStyle: {
            color: '#ff00ff',
            opacity: 0.5,
            width: 2
          },
          silent: true,
          symbol: 'none', //去掉箭头
          data: timeShadowList
        }
      }
      printData.allChartline.push(weightLine)

      //时间轴
      chartOption.xAxis.data = timeValue
      //ad信号
      showAdSignal(printData)
      printData.yAxiSpeed.min = 0
      printData.yAxiSpeed.max = 120
      showCarSpeed(printData)
      showLoadometer(printData)
      printData.legendSleced['手刹信号'] = false
      chartOption.legend.selected = printData.legendSleced
      chartOption.yAxis = [printData.yAxiWeight, printData.yAxiSignal, printData.yAxiSpeed]
      chartOption.series = printData.allChartline
      http_clean({
        url: 'realtime/getRealTimeDataForBase.html',
        method: 'post',
        data: {
          deviceId: props.deviceId
        }
      }).then(res => {
        if (res.data.unit == '2') {
          chartOption.series[0].name = '重量（千克）'
        }
      })
    }

    //计算数据丢失的阈值
    function getAverageTime() {
      /**
       * 计算采样周期,用于判断重量丢失的阈值
       * 取10组历史数据,取中位数作为数据采样的频率
       */
      let temFilterArr = []
      let filterTimeDefault = 0
      if (printData.allData.length > 10) {
        for (let i = 0; i < 11; i++) {
          let time1 = getMilliSecond(printData.allData[i].uploadDate)
          let time2 = getMilliSecond(printData.allData[i + 1].uploadDate)
          let diffTime = (time2 - time1) / 1000
          temFilterArr.push(diffTime)
        }
        temFilterArr.sort(function (a, b) {
          return a - b
        })
        filterTimeDefault = Number(temFilterArr[6]) * 1.5 //取中位数作为计算数据丢失的阈值
      } else {
        filterTimeDefault = (getTimeDiff(printData.allData[1].uploadDate, printData.allData[0].uploadDate) / 1000) * 1.5
      }
      filterTimeDefault = filterTimeDefault > 150 ? filterTimeDefault : 150
      return filterTimeDefault
    }

    /**
     * 获取警报数据,对警报数据分类:离线警报,断电警报;将这两种警报显示在echart折线图上
     */
    function getOffData(reqdata) {
      return new Promise(function (resolve) {
        let alertArr = []
        http_clean({
          url: 'alertlog/queryAllTypeDeviceShutDownAlarmList.html',
          method: 'post',
          data: reqdata
        }).then(result => {
          let len = result.data.length
          alertArr = result.data
          let offlineError = []
          if (len > 0) {
            for (let i = 0; i < len; i++) {
              let sort = 0
              result.data[i].flag = 'alert'
              let item = result.data[i]
              let tim = getMilliSecond(item.offTime)
              for (let _i = sort; _i < printData.allData.length; _i++) {
                let _item = printData.allData[_i]
                let _tim = getMilliSecond(_item.uploadDate)
                if (_tim - tim >= 0 && _i > 0) {
                  sort = _i
                  let errorItem = {}
                  if (item.type == 1) {
                    errorItem = {
                      name: '离线警报',
                      value: '离',
                      xAxis: _i - 1,
                      yAxis: printData.allData[_i - 1].weight,
                      lostime: '',
                      rangeTime: item.totalSecond ? convertTime(item.totalSecond) : '离线中',
                      symbol: 'image://chartImg/ofline_jb.png',
                      symbolSize: 14,
                      symbolOffset: ['0', '-50%']
                    }
                  } else if (item.type == 2) {
                    errorItem = {
                      name: '断电警报',
                      value: '',
                      xAxis: _i - 1,
                      yAxis: printData.allData[_i - 1].weight,
                      lostime: '',
                      rangeTime: item.totalSecond ? convertTime(item.totalSecond) : '断电中',
                      symbol: 'image://chartImg/powerOff2.png',
                      symbolSize: 14,
                      symbolOffset: ['0', '-50%']
                    }
                  }
                  for (let keys in item) {
                    errorItem[keys] = item[keys]
                  }
                  offlineError.push(errorItem)
                  break
                }
              }
            }
          }
          /**
           *   处理警报不在历史数据时间范围之内,但在搜索的时间范围.  将不在历史数据时间范围的数据,补打在历史数据最后时间点上.
           */
          for (let w = 0; w < alertArr.length; w++) {
            let itemAlert = alertArr[w]
            if (
              getMilliSecond(itemAlert.offTime) >
              getMilliSecond(printData.allData[printData.allData.length - 1].uploadDate)
            ) {
              let errorItem
              let index = alertArr.length - w
              if (itemAlert.type == 1) {
                errorItem = {
                  name: '离线警报',
                  value: '离',
                  xAxis: printData.allData.length - index,
                  yAxis: printData.allData[printData.allData.length - index].weight,
                  lostime: '',
                  rangeTime: itemAlert.totalSecond ? convertTime(itemAlert.totalSecond) : '离线中',
                  symbol: 'image://chartImg/ofline_jb.png',
                  symbolSize: 14,
                  symbolOffset: ['0', '-50%']
                }
              } else if (itemAlert.type == 2) {
                errorItem = {
                  name: '断电警报',
                  value: '',
                  xAxis: printData.allData.length - index,
                  yAxis: printData.allData[printData.allData.length - index].weight,
                  lostime: '',
                  rangeTime: itemAlert.totalSecond ? convertTime(itemAlert.totalSecond) : '断电中',
                  symbol: 'image://chartImg/powerOff2.png',
                  symbolSize: 14,
                  symbolOffset: ['0', '-50%']
                }
              }
              if (errorItem) {
                for (let keys in itemAlert) {
                  errorItem[keys] = itemAlert[keys]
                }
                offlineError.push(errorItem)
              }
            }
          }
          printData.offlineAbParams = offlineError
          resolve()
        })
      })
    }

    //转换时间格式
    function convertTime(timeInt) {
      let d, h, m
      if (timeInt >= 0) {
        d = Math.floor(timeInt / 60 / 60 / 24)
        h = Math.floor((timeInt / 60 / 60) % 24)
        m = Math.floor((timeInt / 60) % 60)
      }
      if (d < 1) {
        if (h < 1) {
          // 	 return m+'分'+s+'秒';
          return '约' + m + '分'
        } else {
          return h + '小时' + m + '分'
        }
      } else {
        return d + '天' + h + '小时' + m + '分'
      }
    }

    //绘画ad信号折线,及丢失数据点.
    function showAdSignal(source) {
      source.passageName = []
      source.passageArr = []
      const data = source.allData
      const len = data.length
      let wareStatusArr = [] //手刹信号
      //nullPassArr中对应的值为空的时候,从allPassArr移除对应的通道数据,最终得到filterArr过滤后的数据this.passageArr
      const allPassArr = [] //16通道所有的数组
      const nullPassArr = [] //记录通道全部数据是否为空.,默认值为true
      for (let z = 0; z < 16; z++) {
        const newArr = new Array()
        allPassArr.push(newArr)
        nullPassArr.push(true)
      }
      for (let i = 0; i < len; i++) {
        const handSingal = parseFloat(data[i].handBrakeHardwareStatus) * 10 //手刹信号放大10万倍
        if (handSingal >= 0 || handSingal < 0) {
          wareStatusArr[i] = handSingal
        }
        for (let j = 0; j < 16; j++) {
          const name = 'aCH' + (j + 1)
          const passVal = data[i][name] !== '' ? parseFloat(data[i][name]) : '' //获取16个通道的值;
          allPassArr[j][i] = passVal
          // 如果存在数据 改为 false
          if (passVal > 0 || passVal < 0) {
            nullPassArr[j] = false
          }
        }
      }
      source.legendSleced = {}
      for (let _z = 0; _z < nullPassArr.length; _z++) {
        // 存在数据
        if (!nullPassArr[_z]) {
          const passName = '通道' + (_z + 1)
          source.passageName.push(passName)
          source.passageArr.push(allPassArr[_z])
        }
      }
      source.passageName.push('手刹信号')
      source.passageArr.push(wareStatusArr)
      for (let _i = 0; _i < source.passageName.length; _i++) {
        const obj = {
          name: source.passageName[_i],
          type: 'line',
          yAxisIndex: 1,
          itemStyle: {
            color: lineGetColor(_i)
          },
          lineStyle: {
            width: 2, //折线宽度
            opacity: 0.75
          },
          data: source.passageArr[_i]
        }
        if (source.passageName[_i] === '手刹信号') {
          obj.itemStyle = {
            color: '#8B668B'
          }
          obj.yAxisIndex = 0
        }
        source.allChartline.push(obj)
      }
    }

    //绘画车辆速度折线
    function showCarSpeed(source) {
      let obj = {
        name: '车辆速度',
        type: 'line',
        yAxisIndex: 2,
        itemStyle: {
          color: '#5793f3',
          lineStyle: {
            width: 1, //折线宽度
            opacity: 0.5
          }
        },
        data: source.carSpeedArr,
        areaStyle: {
          color: 'rgba(83, 146, 230,0.5)'
        }
      }
      source.allChartline.push(obj)
    }

    //获取地磅数据在chart图表显示.
    function showLoadometer(source) {
      let orderIndex = 0
      for (let x = 0; x < source.loadometerData.length; x++) {
        const time1 = getMilliSecond(source.loadometerData[x].date)
        for (let i = orderIndex; i < source.allData.length; i++) {
          const time2 = getMilliSecond(source.allData[i].uploadDate)
          orderIndex++
          if (time2 - time1 >= 0) {
            let lmWeight = parseFloat(source.loadometerData[x].weight)
            loadometerVoluation(i - 1, lmWeight, source)
            break
          }
        }
      }
      if (source.loadometerData.length >= 1) {
        let obj = {
          name: '地磅数据',
          type: 'scatter',
          yAxisIndex: 0,
          itemStyle: {
            color: 'orange'
          },
          data: source.loadometerArr
        }
        source.allChartline.push(obj)
      }
    }

    //地磅数据赋值到对应的折线数据位置上,若数据上有值则往后移
    function loadometerVoluation(sort, weight, source) {
      if (sort + 1 <= source.loadometerArr.length) {
        if (source.loadometerArr[sort + 1] == '-') {
          source.loadometerArr[sort + 1] = weight
        } else {
          source.loadometerVoluation(sort + 1, weight)
        }
      }
    }

    expose({ askForData })

    return {
      chartOption
    }
  }
}

function formatMark(dateList) {
  const timeShadowList = []
  const cordonLineMarkArea = []
  if (dateList.length != 0) {
    for (let i = 0; i < dateList.length; i++) {
      const listObj1 = {
        xAxis: dateList[i].startTime,
        lineStyle: {
          type: 'solid'
        },
        label: {
          show: false
        }
      }
      const listObj2 = {
        xAxis: dateList[i].endTime,
        lineStyle: {
          type: 'solid'
        },
        label: {
          show: false
        }
      }
      timeShadowList.push(listObj1)
      timeShadowList.push(listObj2)

      const MarkAreaList = [
        {
          xAxis: dateList[i].startTime
        },
        {
          xAxis: dateList[i].endTime
        }
      ]
      cordonLineMarkArea.push(MarkAreaList)
    }
  }

  return {
    timeShadowList,
    cordonLineMarkArea
  }
}

// 画地图
function queryHistory(data, mapInstance) {
  mapInstance.clearOverlays()
  let path = []
  data.forEach(el => {
    if (el.x && el.y) {
      path.push({ lng: el.x, lat: el.y })
    }
  })
  // 转换百度坐标系
  path = toBDMapPointArr(path)
  // paint map
  const startPos = drawPoint(path[0], startIcon)
  const endPos = drawPoint(path[path.length - 1], endIcon)
  mapInstance.addOverlay(startPos)
  mapInstance.addOverlay(endPos)
  drawLine(path, mapInstance)
}
// 地图画点
function drawPoint(position, icon) {
  const posIcon = new BMapGL.Marker(new BMapGL.Point(position.lng, position.lat), {
    offset: new BMapGL.Size(0, -10),
    icon: new BMapGL.Icon(icon, new BMapGL.Size(30, 30), {
      imageSize: new BMapGL.Size(30, 30)
    })
  })
  posIcon.setZIndex(10)
  return posIcon
}
// 地图画线
function drawLine(path, map) {
  let polyList
  if (path[0].hz) {
    polyList = path
  } else {
    polyList = path.map(el => new BMapGL.Point(el.lng, el.lat))
  }
  // 路径转化为点
  const polyline = new BMapGL.Polyline(polyList, {
    strokeColor: '#4C84F1',
    strokeWeight: 5,
    strokeOpacity: 1,
    fillOpacity: 0.5
  })
  map.addOverlay(polyline)
  setTimeout(() => {
    map.setViewport(polyList)
  }, 100)
}
</script>
