<template>
  <NavBar>
    <template #right>
      <div class="icon-area">
        <van-icon name="search" @click="queryOptions" />
      </div>
    </template>
  </NavBar>
  <div class="flex-main maintain column">
    <Tabs
      @select="selectStatus"
      :status="queryParams.auditStatus"
      :notAuditCount="notAuditCount"
      :auditedCount="auditedCount"
    />
    <PullList ref="listRef" :fetchFn="getOrderList" @select="checkDetail" :params="queryParams">
      <template #default="{ data }">
        <Card :data="data" />
      </template>
    </PullList>
  </div>
  <router-view v-slot="{ Component }">
    <transition :name="animationName">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import { defineComponent, toRefs, reactive, watch, ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { http_clean } from '@/utils/request'
import { useAnimation } from '@/hooks/useAnimation'

import PullList from '@/views/components/PullList.vue'

import Tabs from './components/tabs.vue'
import Card from './components/card.vue'

export default defineComponent({
  components: {
    PullList,
    Tabs,
    Card
  },
  name: 'Maintain',
  setup() {
    // 是否使用动画
    const { animationName } = useAnimation()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      notAuditCount: 0,
      auditedCount: 0
    })

    const listRef = ref(null)
    let active = false

    const queryParams = reactive({
      auditStatus: '1',
      carNumber: computed(() => store.state.maintain.carNumber),
      deviceId: computed(() => store.state.maintain.deviceId)
    })

    onMounted(() => {
      active = true
      selectStatus(store.state.maintain.auditStatus)
    })

    watch(
      () => route.fullPath,
      curr => {
        if (curr === '/settings/maintain') {
          active = true
          selectStatus(store.state.maintain.auditStatus)
        }
      }
    )

    const getOrderList = async gridPager => {
      const data = await http_clean({
        url: 'repairOrder/queryRepairOrder.html',
        method: 'post',
        data: { gridPager }
      })

      return data
    }

    function getCount() {
      let param = {}
      if (store.state.maintain.deviceId) param.deviceId = store.state.maintain.deviceId
      http_clean({
        url: 'repairOrder/queryCountByDeviceId.html',
        method: 'post',
        data: param
      }).then(res => {
        if (res.success) {
          const { data } = res
          state.notAuditCount = data['1'] || 0
          state.auditedCount = data['2'] || 0
        }
      })
    }

    // tab 点击事件
    function selectStatus(source) {
      // 相同的 tab 重复点击拦截
      if (queryParams.auditStatus === source && !active) return
      active = false
      // 记录当前选中 tab
      queryParams.auditStatus = source
      listRef.value && listRef.value.onRefresh()
      getCount()
    }
    function queryOptions() {
      store.commit('maintain/updateState', { auditStatus: queryParams.auditStatus })
      router.push({
        name: 'MaintainSearch'
      })
    }

    const checkDetail = item => {
      router.push({
        name: 'MaintainOrderDetail',
        query: {
          title: item.carNumber,
          payStatus: item.payStatus,
          id: item.id,
          auditStatus: item.auditStatus
        }
      })
    }
    return {
      animationName,
      listRef,
      ...toRefs(state),
      queryParams,
      queryOptions,
      selectStatus,
      checkDetail,
      getOrderList
    }
  }
})
</script>
<style lang="scss" scoped>
.maintain {
  background: #fff;
  overflow: hidden;
}
</style>
