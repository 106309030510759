<template>
  <NavBar>
    <template #right>
      <div :class="['operate', { 'operate-active': carNumber.length > 1 }]" @click="confirmCar">
        <span>确定</span>
      </div>
    </template>
  </NavBar>
  <div class="container search-car">
    <van-search v-model="searchKey" placeholder="请输入4位搜索关键词" @update:model-value="onInput" />
    <van-radio-group class="car-list" @scroll="scrollLoad" v-model="deviceId">
      <van-cell-group class="scroll-item">
        <van-cell
          :title="`${item.carNumber}[${item.deviceId}]`"
          clickable
          @click="pickCar(item)"
          v-for="item in showList"
          :key="item.id"
        >
          <template #right-icon>
            <van-radio :name="item.deviceId" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import http from '@/utils/request'
import { throttle } from '@/utils/common'

export default defineComponent({
  name: 'OptimizeSearchCar',
  setup() {
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      companyId: '',
      carNumber: '',
      deviceId: '',
      searchKey: '',
      carList: [],
      pushList: [],
      showList: []
    })
    let { companyId, carNumber, deviceId } = store.state.optimize
    state.carNumber = carNumber || ''
    state.deviceId = deviceId || ''

    // 前端分页加载
    const pageSize = 100
    let currentPage = 1
    let maxPage = 1
    if (companyId) {
      http({
        url: 'stats/carlist.html',
        method: 'post',
        data: {
          companyId
        }
      }).then(res => {
        if (res.success) {
          maxPage = Math.ceil(res.data.length / pageSize)
          state.carList = [...res.data]
          state.pushList = [...res.data]
          state.showList = state.pushList.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        }
      })
    }

    const onInput = () => {
      if (companyId) {
        currentPage = 1
        state.pushList = state.carList.filter(el => {
          if (!state.searchKey.trim()) return el
          return el.carNumber.indexOf(state.searchKey) > -1
        })
        maxPage = Math.ceil(state.pushList.length / pageSize)
        state.showList = state.pushList.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return
      }
      if (state.searchKey.length > 3) {
        http({
          url: 'stats/carListByCarNumber.html',
          method: 'post',
          data: {
            carNumber: state.searchKey
          }
        }).then(res => {
          if (res.success) {
            state.showList = res.data
          }
        })
      } else {
        state.showList = []
      }
    }
    const confirmCar = () => {
      if (!state.carNumber && state.carNumber.length < 1) return
      store.commit('optimize/updateState', {
        carNumber: state.carNumber,
        deviceId: state.deviceId,
        companyId: state.companyId
      })
      setTimeout(() => {
        router.go(-1)
      }, 500)
    }
    const pickCar = item => {
      state.carNumber = item.carNumber
      state.deviceId = item.deviceId
      state.companyId = companyId
    }

    const scrollLoad = throttle(e => {
      const upTop = e.target.scrollTop
      const clientH = e.target.clientHeight
      const scrollH = e.target.scrollHeight

      if (scrollH - (upTop + clientH) <= 700) {
        if (currentPage >= maxPage) return
        currentPage++
        state.showList = state.showList.concat(
          state.pushList.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        )
      }
    }, 100)

    return {
      ...toRefs(state),
      confirmCar,
      onInput,
      pickCar,
      scrollLoad
    }
  }
})
</script>
<style lang="scss" scoped>
.operate {
  color: #ffffff85;
  &-active {
    color: #fff;
  }
}
.search-car {
  display: flex;
  flex-direction: column;
  .car-list {
    flex-grow: 1;
    overflow-y: scroll;
  }
}
</style>
