<template>
  <div class="base-info card">
    <van-row class="text-cell">
      <van-col span="20">
        <div class="flex-spb">
          <div class="base-info__title">车牌号码:</div>
          <div class="base-info__content">{{ data.carNumber }}</div>
        </div>
      </van-col>
      <van-col span="4" v-if="data.repaired">
        <van-button type="primary" size="mini" @click="dialogRestrict">限制</van-button>
      </van-col>
    </van-row>

    <van-row class="text-cell">
      <van-col span="12">
        <div class="flex-spb">
          <div class="base-info__title">设备ID:</div>
          <div class="base-info__content">{{ data.deviceId }}</div>
        </div>
      </van-col>
      <van-col span="12">
        <div class="flex-spb">
          <div class="base-info__title">限制进场:</div>
          <div class="base-info__content">
            <span :class="data.isPushText === '限制进厂' ? 'red' : 'green'">
              {{ data.isPushText }}
            </span>
          </div>
        </div>
      </van-col>
    </van-row>

    <van-row class="text-cell">
      <van-col span="24">
        <div class="flex-spb">
          <div class="base-info__title">处理时间:</div>
          <div class="base-info__content">{{ data.updateTime }}</div>
        </div>
      </van-col>
    </van-row>

    <van-row class="text-cell">
      <van-col span="12">
        <div class="flex-spb">
          <div class="base-info__title">处理人:</div>
          <div class="base-info__content">{{ data.handleUser }}</div>
        </div>
      </van-col>
      <van-col span="12">
        <div class="flex-spb">
          <div class="base-info__title">审核状态:</div>
          <div class="base-info__content">
            <span :class="showStatus(data.status, 'className', 'red')">
              {{ showStatus(data.status, 'text', '审核不通过') }}
            </span>
          </div>
        </div>
      </van-col>
    </van-row>

    <van-row class="text-cell">
      <van-col span="24">
        <div class="flex-spb">
          <div class="base-info__title">厂区名称:</div>
          <div class="base-info__content">
            {{ data.companyName }}
          </div>
        </div>
      </van-col>
    </van-row>

    <van-row class="text-cell">
      <van-col span="24">
        <div class="flex-spb">
          <div class="base-info__title">问题来源:</div>
          <div class="base-info__content">{{ data.source === 2 ? '平台发现' : '客户投诉' }}</div>
        </div>
      </van-col>
    </van-row>

    <van-row class="text-cell">
      <van-col span="24">
        <div class="flex-spb">
          <div class="base-info__title">处理原因:</div>
          <div class="base-info__content">{{ data.handleReason }}</div>
        </div>
      </van-col>
    </van-row>

    <van-row class="text-cell">
      <van-col span="24">
        <div class="flex-spb">
          <div class="base-info__title">维修状态:</div>
          <div class="base-info__content">
            <span v-if="!data.haveRepaired">--</span>
            <span v-if="data.haveRepaired">
              <span>{{ data.haveRepaired.repairedUserName }}&nbsp;</span>
              <span>{{ data.haveRepaired.repairedTime }}&nbsp;</span>
              <span>
                <span v-if="data.haveRepaired.repairSubdivideList && data.haveRepaired.repairSubdivideList.length > 0">
                  <span v-for="lis of data.haveRepaired.repairSubdivideList" :key="lis">
                    <span>{{ lis }}</span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
      </van-col>
    </van-row>
    <van-dialog
      v-model:show="showRestrict"
      title="是否限制进场？"
      show-cancel-button
      confirm-button-color="#1989fa"
      @confirm="confirmRestrict"
    >
      <div class="restrict-group">
        <van-radio-group v-model="restrictChecked">
          <div class="restrictOption">
            <van-radio name="0">不限制</van-radio>
          </div>
          <div class="restrict-option">
            <van-radio name="1">限制</van-radio>
          </div>
          <div class="restrict-hour">
            <van-radio name="2">自定义限制(默认6hr)</van-radio>
            <input type="text" v-model="restrictTime" @touchstart.stop="numInputClick" />
          </div>
        </van-radio-group>
      </div>
    </van-dialog>

    <van-number-keyboard
      v-model="restrictTime"
      :show="numInputShow"
      :maxlength="6"
      @blur="numInputShow = false"
      style="z-index: 9999"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { Dialog } from 'vant'
import { http_clean } from '@/utils/request'
export default {
  components: { [Dialog.Component.name]: Dialog.Component },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  emits: ['confirm'],
  setup(props, { emit }) {
    // if (el.status === 0) {
    //   el.status = '待审核'
    //   el.statusClass = 'orange'
    // } else if (el.status === 1) {
    //   el.status = '审核通过'
    //   el.statusClass = 'green'
    // } else {
    //   el.status = '审核不通过'
    //   el.statusClass = 'red'
    // }
    const statusList = [
      {
        value: 0,
        text: '待审核',
        className: 'orange'
      },
      {
        value: 1,
        text: '审核通过',
        className: 'green'
      }
    ]

    const showRestrict = ref(false)

    const numInputShow = ref(false)

    const restrictChecked = ref('')

    const restrictTime = ref('6')

    const dialogRestrict = () => {
      showRestrict.value = true
    }

    const numInputClick = () => {
      restrictChecked.value = '2'
      numInputShow.value = true
    }
    const confirmRestrict = () => {
      const ids = props.data.repaired.id
      const isPush = restrictChecked.value === '0' ? '0' : '1'
      const params = {
        ids,
        isPush
      }
      if (restrictChecked.value === '2') {
        params.time = +restrictTime.value
      }
      http_clean({
        url: 'deviceRepaired/enterFactoryLimitBatch.html',
        method: 'post',
        data: params
      }).then(() => {
        restrictTime.value = '6'
        // initData()
        emit('confirm')
      })
    }

    const showStatus = (val, key, defaultVal) => {
      const item = statusList.find(el => el.value === val)
      return item ? item[key] : defaultVal
    }
    return {
      showStatus,
      dialogRestrict,
      restrictChecked,
      numInputClick,
      restrictTime,
      showRestrict,
      numInputShow,
      confirmRestrict
    }
  }
}
</script>
<style lang="scss" scoped>
.base-info {
  .text-cell {
    font-size: 12px;
    line-height: 14px;
  }
  &__title {
    min-width: 60px;
  }
  &__content {
    flex: 1;
  }
}
.restrict-group {
  font-size: 12px;
  padding: 8px 12px;
  .restrict-option {
    padding: 4px 0;
  }
  .restrict-hour {
    display: flex;
    justify-content: space-between;
  }
}
</style>
