<template>
  <NavBar :is-return="false"></NavBar>
  <div class="settings container">
    <van-grid :column-num="3">
      <van-grid-item text="维修单" to="/settings/maintain">
        <template #icon>
          <van-image :src="repairImgSrc" class="setting-img" />
        </template>
      </van-grid-item>
      <van-grid-item text="GPS审核" to="/settings/gps-audit">
        <template #icon>
          <van-image :src="GPSImgSrc" class="setting-img" />
        </template>
      </van-grid-item>
      <van-grid-item text="同步项目车辆" to="/settings/sync-car">
        <template #icon>
          <van-image :src="SyncImgSrc" class="setting-img" />
        </template>
      </van-grid-item>
      <van-grid-item text="设备待解除限制记录" to="/settings/device-relieve-record">
        <template #icon>
          <van-image :src="orderImgSrc" class="setting-img" />
        </template>
      </van-grid-item>
    </van-grid>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import repairImgSrc from '@/assets/img/setting_repair.png'
import GPSImgSrc from '@/assets/img/setting_gps_check.png'
import SyncImgSrc from '@/assets/img/setting_sync_car.png'
import orderImgSrc from '@/assets/img/setting_order_monitor.png'

export default defineComponent({
  name: 'Settings',
  setup() {
    const store = useStore()
    // 清空搜索的值
    store.commit('gpsAudit/resetState')
    store.commit('maintain/resetState')
    store.commit('optimize/resetState')
    store.commit('OrderManage/resetState')
    if (!store.state.commonData.companyData) {
      store.dispatch('commonData/companyTreeRequest')
    }

    return {
      orderImgSrc,
      repairImgSrc,
      GPSImgSrc,
      SyncImgSrc
    }
  }
})
</script>
<style lang="scss" scoped>
.settings {
  background-color: #fff;
}
.setting-img {
  height: 50px;
  width: 50px;
}
</style>
