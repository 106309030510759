import { reactive, ref } from 'vue'
import { problemTypeList } from '@/utils/types'
import { useStore } from 'vuex'
import { Toast, Dialog } from 'vant'
import http from '@/utils/http'
import { formatTimeStr, getMilliSecond, getTimeDiff } from '@/utils/common'

const optimizeOptions = [
  { text: '问题记录', value: 0 },
  { text: '数据优化', value: 1 }
]
const waveListColumns = ['0.1', '0.2', '0.3', '0.4', '0.5', '0.6', '0.7', '0.8', '0.9', '1.0']

const sensorList = [
  { id: 1, text: '传感器1', checked: false },
  { id: 2, text: '传感器2', checked: false },
  { id: 3, text: '传感器3', checked: false },
  { id: 4, text: '传感器4', checked: false },
  { id: 5, text: '传感器5', checked: false },
  { id: 6, text: '传感器6', checked: false },
  { id: 7, text: '传感器7', checked: false },
  { id: 8, text: '传感器8', checked: false },
  { id: 9, text: '传感器9', checked: false },
  { id: 10, text: '传感器10', checked: false },
  { id: 11, text: '传感器11', checked: false },
  { id: 12, text: '传感器12', checked: false },
  { id: 13, text: '传感器13', checked: false },
  { id: 14, text: '传感器14', checked: false },
  { id: 15, text: '传感器15', checked: false },
  { id: 16, text: '传感器16', checked: false }
]

export default function () {
  const store = useStore()
  const state = reactive({
    isDisabled: true, // 提交问题记录后才能触发优化事件
    currentTab: 0,
    optimizeSort: 0,
    pickDateKey: '', // 保存时间选择的key
    problemType: 0, // 0 问题类型 1 算法 2 硬件 3 司机 4 对接 5 运维 6 安装 7 平台 8 磅单
    problemPicker: false,
    currentProbItem: '', // 当前问题类型选择级别，确定picker选项
    proSelectedItem: [], // 当前选中问题类型整个值
    numKeyShow: false, // 数字输入展示控制
    wavePicker: false,
    datePickerShow: false,
    pickedDate: new Date(),
    recordLoading: false,
    optiLoading: false,
    recordTransportLoading: false, //记录上货点loading
    numInputKey: ''
  })
  const problemState = reactive({
    startTime_problem: '',
    endTime_problem: '',
    problemType: '',
    problemTypeIndex: undefined,
    problemClass: '',
    problemClassIndex: undefined,
    sensorPhenName: '', // 传感器名称
    phenomenon: '',
    phenomenonIndex: undefined,
    phenomenonInput: '',
    repairGuide: '',
    isPush: 0
  })
  // 数据优化的字段
  const dataState = reactive({
    zeroBeforeDate: '', // 上货前置零时间
    upStartDate: '', //上货开始时间
    upEndDate: '', //上货结束时间
    downStartDate: '', // 卸货开始时间
    downEndDate: '', // 卸货结束时间
    zeroAfterDate: '', // 卸货后置零时间
    startWeight: '', // 上货前重量
    weight: '', // 重量
    endWeight: '', // 卸货后置零时间
    isWave: false, // 数据波动
    waveWeight: '0.0', // 波动范围
    isDiagonal: false // 上货曲线
  })
  // 问题选项的选择器数据，根据不同的选项重新加载数据
  const problemListColumns = ref([])

  // 选择问题类型
  const pickProblemType = () => {
    problemListColumns.value = problemTypeList.map(el => el.name)
    state.problemPicker = true
    state.currentProbItem = 'problemType'
    problemState.problemClass = ''
    problemState.problemClassIndex = undefined
    problemState.phenomenon = ''
    problemState.phenomenonIndex = undefined
    problemState.phenomenonInput = ''
    problemState.repairGuide = ''
    state.proSelectedItem = []
  }

  // 选择问题分类
  const pickProblemClass = () => {
    problemListColumns.value = state.proSelectedItem.children?.map(el => el.name)
    state.problemPicker = true
    state.currentProbItem = 'problemClass'
    problemState.sensorPhenName = ''
    problemState.phenomenon = ''
    problemState.phenomenonIndex = undefined
    problemState.phenomenonInput = ''
    problemState.repairGuide = ''
  }

  // 选择问题现象
  const pickPhenomenon = () => {
    problemListColumns.value = state.proSelectedItem.children[problemState.problemClassIndex]?.phenomenon?.map(
      el => el.name
    )
    state.problemPicker = true
    state.currentProbItem = 'problemPhenomenon'
    problemState.repairGuide = ''
  }

  // 选择传感器名称
  const pickSensorName = () => {
    problemListColumns.value = sensorList
    state.problemPicker = true
    state.currentProbItem = 'problemSensor'
    problemState.repairGuide = ''
  }

  const pickRepairGuide = () => {
    problemListColumns.value = state.proSelectedItem.children[problemState.problemClassIndex]?.phenomenon[
      problemState.phenomenonIndex
    ]?.repairGuide.map(el => el.name)
    state.problemPicker = true
    state.currentProbItem = 'problemRepair'
    problemState.repairGuide = ''
  }
  // 时间弹框的确认事件列表，优化if判断
  const optionsList = {
    problemType: {
      parentKey: 'problemType', // 内容赋值的key
      indexKey: 'problemTypeIndex', // index赋值的key
      action: () => {
        const problems = problemTypeList.filter(el => el.name === problemState.problemType)[0]
        state.proSelectedItem = problems
      }
    },
    problemClass: {
      parentKey: 'problemClass',
      indexKey: 'problemClassIndex'
    },
    problemSensor: {
      parentKey: 'sensorPhenName',
      subKey: 'text'
    },
    problemPhenomenon: {
      parentKey: 'phenomenon',
      indexKey: 'phenomenonIndex'
    },
    problemRepair: {
      parentKey: 'repairGuide'
    }
  }

  // 确认选项
  const confirmProblemPick = (val, i) => {
    const key = state.currentProbItem
    if (!key) return
    let { parentKey, indexKey, action, subKey } = optionsList[key]
    problemState[parentKey] = subKey ? val.text : val
    indexKey && (problemState[indexKey] = i)
    action && action()
    state.problemPicker = false
  }

  // 计算 tab 下滑线位置
  function setTransfer(index) {
    const tabEl = document.querySelector('.optimize-tab')
    const tabWidth = tabEl.getBoundingClientRect().width
    const lineEl = document.querySelector('.optimize-tabs__line')
    lineEl.setAttribute('style', `transform: translateX(${tabWidth / 2 + index * tabWidth}px) translateX(-50%)`)
  }
  // tab点击时间
  const tabClick = index => {
    state.currentTab = index
    setTransfer(index)
  }
  // 时间选择，使用key区分字段
  const pickTime = key => {
    state.pickDateKey = key
    state.pickedDate = new Date()
    state.datePickerShow = true
  }
  const confirmWavePick = val => {
    dataState.waveWeight = val
    state.wavePicker = false
  }

  const dateOptionList = {
    startTime_problem: problemState,
    endTime_problem: problemState,
    zeroBeforeDate: dataState,
    upStartDate: dataState,
    upEndDate: dataState,
    downStartDate: dataState,
    downEndDate: dataState,
    zeroAfterDate: dataState
  }

  // 时间选择事件
  const confirmDate = date => {
    const key = state.pickDateKey
    dateOptionList[key][key] = formatTimeStr(date)
    state.datePickerShow = false
  }
  // 展示数字输入框
  const showNumInput = key => {
    state.numInputKey = key
    state.numKeyShow = true
  }
  // 数字输入
  const onNumInput = val => {
    const key = state.numInputKey
    dataState[key] = dataState[key] + val
  }
  // 删除数字
  const onNumDelete = () => {
    const key = state.numInputKey
    if (dataState[key] === '') return
    dataState[key] = dataState[key].substring(0, dataState[key].length - 1)
  }
  const recordProb = () => {
    if (!problemState.startTime_problem || !problemState.endTime_problem) {
      Toast('问题起始时间不能为空')
      return
    }
    if (!problemState.problemType || !problemState.problemClass) {
      Toast('请选择问题分类')
      return
    }
    if (!problemState.phenomenon && !problemState.phenomenonInput) {
      Toast('问题现象不能为空')
      return
    }
    let resultPhenStr = problemState.phenomenon ? problemState.phenomenon : problemState.phenomenonInput
    if (problemState.problemTypeIndex === 1 && problemState.problemClassIndex === 1) {
      if (!problemState.sensorPhenName) {
        Toast('问题现象不能为空')
        return
      }
      resultPhenStr = `${problemState.sensorPhenName}-${problemState.phenomenon}`
    }
    state.recordLoading = true
    http({
      loadText: '请求发送中...',
      url: 'realtime/getRealTimeData.html',
      method: 'post',
      data: {
        deviceId: store.state.optimize.deviceId
      }
    })
      .then(res => {
        let params = {
          programType: `${problemState.problemTypeIndex + 1}0${problemState.problemClassIndex + 1}`,
          deviceId: store.state.optimize.deviceId,
          version: res.data.version ? res.data.version : '',
          startDate: problemState.startTime_problem,
          endDate: problemState.endTime_problem,
          reason: resultPhenStr,
          exceptionType: problemState.problemTypeIndex + 1,
          repairedGuide: problemState.repairGuide,
          isPush: problemState.isPush,
          coefficient: res.data.coef ? res.data.coef.join(',') : ''
        }
        http({
          url: 'dataExceptionRecord/addDataException.html',
          method: 'post',
          data: params
        })
          .then(result => {
            Toast(result.message)
            state.recordLoading = false
            if (result.success) {
              state.isDisabled = false
            }
          })
          .catch(() => {
            state.recordLoading = false
          })
      })
      .catch(() => {
        state.recordLoading = false
      })
  }
  // 提交设备故障
  const submitDevice = () => {
    if (!problemState.startTime_problem || !problemState.endTime_problem) {
      Toast('问题起始时间不能为空')
      return
    }
    if (!problemState.problemType || !problemState.problemClass) {
      Toast('请选择问题分类')
      return
    }
    if (!problemState.phenomenon && !problemState.phenomenonInput) {
      Toast('问题现象不能为空')
      return
    }
    let resultPhenStr = problemState.phenomenon ? problemState.phenomenon : problemState.phenomenonInput
    if (problemState.problemTypeIndex === 1 && problemState.problemClassIndex === 1) {
      if (!problemState.sensorPhenName) {
        Toast('问题现象不能为空')
        return
      }
      resultPhenStr = `${problemState.sensorPhenName}-${problemState.phenomenon}`
    }
    state.recordLoading = true

    http({
      loadText: '请求发送中...',
      url: 'deviceRepaired/validateRecord.html',
      method: 'post',
      data: {
        deviceId: store.state.optimize.deviceId
      }
    }).then(res => {
      let params = {
        programType: `${problemState.problemTypeIndex + 1}0${problemState.problemClassIndex + 1}`,
        deviceId: store.state.optimize.deviceId, //编号
        carNumber: store.state.optimize.carNumber, //车牌,地磅车牌，哪个有数据显示哪个
        faultTypeName: problemState.problemClass, //故障类型
        faultTime: problemState.startTime_problem, //故障时间
        faultPhenomenon: resultPhenStr, //故障现象:例  传感器1-平直
        isPush: problemState.isPush, //是否推送
        repairedGuide: problemState.repairGuide, //维修指导
        repairedRecords: '', //维修记录
        repairedStatus: '', //维修状态(故障状态)
        repairedTime: '', //维修时间
        repairedUser: '', //维修人员
        remark: '', //备注
        repairedConclusion: '' //维修总结
      }
      if (res.valid) {
        Dialog.confirm({
          title: '提示',
          message: `${res.message}确认要继续提交?`,
          confirmButtonColor: '#1989fa'
        })
          .then(() => {
            http({
              loadText: '请求发送中...',
              url: 'deviceRepaired/insertDeviceRepaired.html',
              method: 'post',
              data: params
            }).then(result => {
              Toast(result.message)
              state.recordLoading = false
            })
          })
          .catch(() => {
            state.recordLoading = false
          })
      } else {
        http({
          loadText: '请求发送中...',
          url: 'deviceRepaired/insertDeviceRepaired.html',
          method: 'post',
          data: params
        }).then(result => {
          Toast(result.message)
          state.recordLoading = false
        })
      }
    })
  }
  //判断时间是否在问题记录时间区间内
  const timeInProblemStartEnd = () => {
    let result = true
    if (!problemState.startTime_problem || !problemState.endTime_problem) {
      Toast('问题记录起始时间不能为空')
      result = false
      return result
    }
    const stNum_pb = getMilliSecond(problemState.startTime_problem)
    const etNum_pb = getMilliSecond(problemState.endTime_problem)
    if (!stNum_pb || !etNum_pb) {
      Toast('问题记录起始时间格式不正确')
      result = false
      return result
    }

    const checkArr = [
      { name: '上货前置零起始时间', time: dataState.zeroBeforeDate },
      { name: '上货点起始时间', time: dataState.upStartDate },
      { name: '上货点结束时间', time: dataState.upEndDate },
      { name: '卸货点起始时间', time: dataState.downStartDate },
      { name: '卸货点结束时间', time: dataState.downEndDate },
      { name: '卸货后置零结束时间', time: dataState.zeroAfterDate }
    ]

    for (let item of checkArr) {
      if (item.time) {
        let _tn = getMilliSecond(item.time)
        if (_tn < stNum_pb || _tn > etNum_pb) {
          Toast(item.name + '需要在问题起始结束时间内')
          result = false
          break
        }
      }
    }
    return result
  }

  // 时间格式和必填的校验
  const checkWeight = () => {
    let result = true
    if (!dataState.weight) {
      Toast('请输入重量值...')
      result = false
    }
    return result
  }

  //数据优化提交
  const makeData = () => {
    let exceptionType, proType
    if (!problemState.problemType || !problemState.problemClass) {
      Toast('请选择问题分类')
      return
    }
    if (problemState.problemType) {
      proType = `${+problemState.problemTypeIndex + 1}0${+problemState.problemClassIndex + 1}`
      if (problemState.problemClass == '其他') {
        proType = `${+problemState.problemTypeIndex + 1}00`
      }
      exceptionType = +problemState.problemTypeIndex + 1
    }
    const deviceId = store.state.optimize.deviceId
    const upStartDate = dataState.upStartDate
    const upEndDate = dataState.upEndDate
    const weight = dataState.weight
    const downStartDate = dataState.downStartDate
    const downEndDate = dataState.downEndDate
    const zeroBeforeDate = dataState.zeroBeforeDate
    const zeroAfterDate = dataState.zeroAfterDate
    const startWeight = dataState.startWeight
    const endWeight = dataState.endWeight
    if (deviceId == '') {
      Toast('请选择车牌号')
      return
    }
    const isSubmit = checkWeight()
    if (!isSubmit) return

    const isWave = dataState.isWave //返回 true/false
    const isDiagonal = dataState.isDiagonal //上卸货曲线,返回 true/false
    if (zeroBeforeDate && upStartDate) {
      if (getTimeDiff(zeroBeforeDate, upStartDate) > 0) {
        Toast('上货前置零时间不能大于上货时间')
        return
      }
    }
    if (upStartDate && upEndDate) {
      if (getTimeDiff(upStartDate, upEndDate) > 0) {
        Toast('上货开始时间不能大于上货结束时间')
        return
      }
    }
    if (upEndDate && downStartDate) {
      if (getTimeDiff(upEndDate, downStartDate) > 0) {
        Toast('上货结束时间不能大于卸货开始时间')
        return
      }
    }
    if (downStartDate && downEndDate) {
      if (getTimeDiff(downStartDate, downEndDate) > 0) {
        Toast('卸货开始时间不能大于卸货结束时间')
        return
      }
    }
    if (downEndDate && zeroAfterDate) {
      if (getTimeDiff(downEndDate, zeroAfterDate) > 0) {
        Toast('卸货结束时间不能大于卸货后置零结束时间')
        return
      }
    }
    let isTime = timeInProblemStartEnd()
    if (!isTime) return
    state.optiLoading = true
    Dialog.confirm({
      title: '提示',
      message: `是否确认所有时间段没问题，然后开始进行数据替换吗？`,
      confirmButtonColor: '#1989fa'
    })
      .then(() => {
        http({
          loadText: '请求发送中...',
          url: 'system/makedata.html',
          method: 'post',
          data: {
            deviceId: deviceId,
            upStartDate: upStartDate,
            upEndDate: upEndDate,
            weight: weight,
            downStartDate: downStartDate,
            downEndDate: downEndDate,
            isWave: isWave,
            zeroBeforeDate: zeroBeforeDate,
            zeroAfterDate: zeroAfterDate,
            isDiagonal: isDiagonal,
            startWeight: startWeight,
            endWeight: endWeight,
            waveWeight: dataState.waveWeight,
            exceptionType: exceptionType,
            programType: proType,
            orderId: ''
          }
        }).then(result => {
          Toast(result.message)
          state.optiLoading = false
        })
      })
      .catch(() => {
        state.optiLoading = false
      })
  }

  // 记录上货点
  const recordTransportData = () => {
    const deviceId = store.state.optimize.deviceId
    const upStartDate = dataState.upStartDate
    const upEndDate = dataState.upEndDate
    const weight = dataState.weight
    const downStartDate = dataState.downStartDate
    const downEndDate = dataState.downEndDate
    const carNumber = store.state.optimize.carNumber
    const isSubmit = checkWeight()

    if (!isSubmit) {
      return
    }
    state.recordTransportLoading = true
    Dialog.confirm({
      title: '提示',
      message: `是否保存上卸货点`,
      confirmButtonColor: '#1989fa'
    })
      .then(() => {
        http({
          loadText: '正在保存上卸货点...',
          url: '/stats/recordtransportdata.html',
          method: 'post',
          data: {
            deviceId: deviceId,
            upStartDate: upStartDate,
            upEndDate: upEndDate,
            weight: weight,
            downStartDate: downStartDate,
            downEndDate: downEndDate,
            carNumber: carNumber
          }
        }).then(result => {
          Toast(result.message)
          state.recordTransportLoading = false
        })
      })
      .catch(() => {
        state.recordTransportLoading = false
      })
  }

  return {
    state,
    problemListColumns,
    problemState,
    dataState,
    constants: {
      optimizeOptions,
      waveListColumns
    },
    setTransfer,
    tabClick,
    pickFuncs: {
      pickTime,
      pickProblemType,
      pickProblemClass,
      pickPhenomenon,
      pickSensorName,
      pickRepairGuide,
      confirmProblemPick,
      confirmWavePick,
      confirmDate,
      recordProb,
      submitDevice,
      makeData,
      showNumInput,
      onNumInput,
      onNumDelete,
      recordTransportData
    }
  }
}
