<template>
  <div class="gps-query">
    <NavBar />
    <div class="container">
      <van-cell-group>
        <van-field
          label="车牌号码"
          v-model="searchOptions.carNumber"
          readonly
          @click="selectCar"
          placeholder="请选择车牌号码"
        />
        <van-field label="问题来源" v-model="searchOptions.sourceText" readonly @click="pickSourceType = true" />
        <van-field
          label="起始时间"
          v-model="searchOptions.startTime"
          readonly
          placeholder="请选择起始时间"
          @click="pickStartTime"
        />
        <van-field
          label="截止时间"
          v-model="searchOptions.endTime"
          readonly
          placeholder="请选择结束时间"
          @click="pickEndTime"
        />
        <van-field v-model="searchOptions.searchKey" label="审核人" />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" @click="submitOption">查询</van-button>
      </div>
      <van-popup :show="pickSourceType" @click-overlay="pickSourceType = false" round position="bottom">
        <van-picker
          title="选择问题来源"
          show-toolbar
          :columns="sourceTypeList"
          @confirm="confirmSourceType"
          @cancel="pickSourceType = false"
        />
      </van-popup>
      <!-- =============选择时间 ============-->
      <van-popup
        :show="datePickerIsShow"
        position="bottom"
        round
        style="height: '46%'"
        @click-overlay="datePickerIsShow = false"
      >
        <van-datetime-picker
          v-model="selectedDate"
          type="datetime"
          title="选择时间"
          @confirm="pickTime"
          @cancel="datePickerIsShow = false"
        />
      </van-popup>
      <!-- =============选择时间 end ============ -->
    </div>
  </div>
</template>
<script>
import { defineComponent, toRefs } from 'vue'
import queryModules from './queryModules'

export default defineComponent({
  name: 'GpsQueryOptions',
  setup() {
    const { state, functions, constants } = queryModules()
    return {
      ...toRefs(state),
      ...functions,
      ...constants
    }
  }
})
</script>
<style lang="scss" scoped>
.edit__submit {
  margin-top: 12px;
}
.gps-query {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  display: flex;
  flex-direction: column;
}
</style>
