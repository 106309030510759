import { reactive, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { formatTimeStr } from '@/utils/common'

const sourceTypeList = [
  { text: '全部', value: '' },
  { text: '客户投诉', value: '1' },
  { text: '平台发现', value: '2' }
]

export default function name() {
  const store = useStore()
  const state = reactive({
    pickSourceType: false,
    searchOptions: {
      source: '',
      sourceText: '全部',
      carNumber: '',
      deviceId: '',
      startTime: '',
      endTime: '',
      searchKey: ''
    },
    selectedDate: new Date(),
    datePickerIsShow: false,
    isStart: true
  })
  watchEffect(() => {
    state.searchOptions = store.state.gpsAudit
  })
  const router = useRouter()
  const submitOption = () => {
    store.commit('gpsAudit/updateState', state.searchOptions)
    setTimeout(() => {
      router.go(-1)
    }, 500)
  }
  const selectCar = () => {
    router.push({
      name: 'GpsSearchCar'
    })
  }
  const confirmSourceType = val => {
    state.pickSourceType = false
    state.searchOptions.sourceText = val.text
    state.searchOptions.source = val.value
  }
  const pickStartTime = () => {
    if (state.searchOptions.startTime) {
      state.selectedDate = new Date(state.searchOptions.startTime)
    }
    state.datePickerIsShow = true
    state.isStart = true
  }
  const pickEndTime = () => {
    if (state.searchOptions.endTime) state.selectedDate = new Date(state.searchOptions.endTime)
    state.datePickerIsShow = true
    state.isStart = false
  }
  const pickTime = val => {
    state.datePickerIsShow = false
    if (state.isStart) {
      state.searchOptions.startTime = formatTimeStr(val)
      return
    }
    state.searchOptions.endTime = formatTimeStr(val)
  }
  return {
    state,
    functions: {
      submitOption,
      selectCar,
      confirmSourceType,
      pickStartTime,
      pickEndTime,
      pickTime
    },
    constants: {
      sourceTypeList
    }
  }
}
