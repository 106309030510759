<template>
  <div class="cover">
    <NavBar />
    <div class="container">
      <van-cell-group>
        <van-field label="厂区名称" v-model="companyName" readonly @click="findCompany" placeholder="请选择车牌号码" />
        <van-field
          label="车牌号码"
          v-model="searchOptions.carNumber"
          readonly
          @click="selectCar"
          placeholder="请选择车牌号码"
        />
        <van-field
          label="解除限制进厂"
          v-model="searchOptions.limitStatusStr"
          readonly
          @click="pickerState.pickLimitType = true"
        />
        <van-field
          label="起始时间"
          v-model="searchOptions.startTime"
          readonly
          placeholder="请选择起始时间"
          @click="pickStartTime"
        />
        <van-field
          label="截止时间"
          v-model="searchOptions.endTime"
          readonly
          placeholder="请选择结束时间"
          @click="pickEndTime"
        />
        <van-field v-model="searchOptions.searchKey" label="关键字" />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" @click="submitOption">查询</van-button>
      </div>
      <van-popup
        :show="pickerState.pickLimitType"
        @click-overlay="pickerState.pickLimitType = false"
        round
        position="bottom"
      >
        <van-picker
          title="选择解除限制进厂状态"
          show-toolbar
          :columns="limitTypeList"
          @confirm="confirmLimitType"
          @cancel="pickerState.pickLimitType = false"
        />
      </van-popup>
      <!-- =============选择时间 ============-->
      <van-popup
        :show="pickerState.datePickerIsShow"
        position="bottom"
        round
        style="height: '46%'"
        @click-overlay="pickerState.datePickerIsShow = false"
      >
        <van-datetime-picker
          v-model="pickerState.selectedDate"
          type="datetime"
          title="选择时间"
          @confirm="pickTime"
          @cancel="pickerState.datePickerIsShow = false"
        />
      </van-popup>
      <!-- =============选择时间 end ============ -->
    </div>
    <router-view v-slot="{ Component }">
      <transition :name="animationName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, provide, inject } from 'vue'
import { useRouter } from 'vue-router'
import { formatTimeStr } from '@/utils/common'
import { useAnimation } from '@/hooks/useAnimation'

const limitTypeList = [
  { text: '全部', value: '' },
  { text: '未解除', value: '0' },
  { text: '已解除', value: '1' }
]

export default defineComponent({
  name: 'DeviceRelieveRecordQuery',
  setup() {
    const searchOptions = reactive({
      carNumber: '',
      deviceId: '',
      limitStatus: '',
      limitStatusStr: '',
      startTime: '',
      endTime: '',
      searchKey: ''
    })

    const pickerState = reactive({
      pickLimitType: false,
      selectedDate: new Date(),
      datePickerIsShow: false,
      isStart: true
    })

    const { animationName } = useAnimation()

    const companyName = ref('')
    const companyId = ref('')

    const updateCompanyId = id => {
      companyId.value = id
    }
    const updateCompanyName = com => {
      companyName.value = com
    }

    // 向下提供数据和方法，更新公司名称和公司ID

    provide('companyName', companyName)
    provide('selectedCompanyId', companyId)
    provide('updateCompanyId', updateCompanyId)
    provide('updateCompanyName', updateCompanyName)
    const updatedCar = ({ deviceId, carNumber }) => {
      searchOptions.deviceId = deviceId
      searchOptions.carNumber = carNumber
    }

    provide('carNumber', searchOptions.carNumber)
    provide('deviceId', searchOptions.deviceId)
    provide('updatedCar', updatedCar)

    const relieveOptions = inject('relieveOptions')

    initOptions(relieveOptions.value)

    function initOptions(options) {
      let limitStatusStr = ''

      const { carNumber, deviceId, limitStatus, startTime, endTime, searchKey } = options

      if (limitStatus) {
        const type = limitTypeList.find(el => el.value === limitStatus)
        limitStatusStr = type.text
      }
      searchOptions.carNumber = carNumber
      searchOptions.deviceId = deviceId
      searchOptions.limitStatus = limitStatus
      searchOptions.limitStatusStr = limitStatusStr
      searchOptions.startTime = startTime
      searchOptions.endTime = endTime
      searchOptions.searchKey = searchKey
      companyName.value = options.companyName || ''
      companyId.value = options.companyId || ''
    }

    const pickStartTime = () => {
      if (searchOptions.startTime) {
        pickerState.selectedDate = new Date(searchOptions.startTime)
      }
      pickerState.datePickerIsShow = true
      pickerState.isStart = true
    }
    const pickEndTime = () => {
      if (searchOptions.endTime) {
        pickerState.selectedDate = new Date(searchOptions.endTime)
      }
      pickerState.datePickerIsShow = true
      pickerState.isStart = false
    }
    const pickTime = val => {
      pickerState.datePickerIsShow = false
      if (pickerState.isStart) {
        searchOptions.startTime = formatTimeStr(val)
        return
      }
      searchOptions.endTime = formatTimeStr(val)
    }

    const router = useRouter()

    const findCompany = () => {
      router.push({
        name: 'DeviceRelieveRecordQueryCompany'
      })
    }

    const selectCar = () => {
      router.push({
        name: 'DeviceRelieveRecordQueryCar'
      })
    }

    const confirmLimitType = val => {
      pickerState.pickLimitType = false
      searchOptions.limitStatusStr = val.text
      searchOptions.limitStatus = val.value
    }
    const updateRelieveOption = inject('updateRelieveOption')

    const submitOption = () => {
      updateRelieveOption({
        ...searchOptions,
        companyName: companyName.value,
        factoryId: companyId.value
      })
      setTimeout(() => {
        router.go(-1)
      }, 100)
    }

    return {
      animationName,
      searchOptions,
      companyName,
      companyId,
      limitTypeList,
      pickerState,
      pickStartTime,
      pickEndTime,
      pickTime,
      findCompany,
      selectCar,
      confirmLimitType,
      submitOption
    }
  }
})
</script>
<style lang="scss" scoped>
.edit__submit {
  margin-top: 12px;
}
.cover {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  display: flex;
  flex-direction: column;
}
</style>
