<template>
  <div class="chart-timer flex-spa">
    <span>时间段</span>
    <div class="chart-timer__input">
      <input type="text" v-model="startTime" @click="pickTime('start')" />
      <span>-</span>
      <input type="text" v-model="endTime" @click="pickTime('end')" />
    </div>
    <van-button type="primary" size="mini" class="search-btn" @click="$emit('search')">查询</van-button>
  </div>
  <van-popup
    :show="datePickerShow"
    position="bottom"
    round
    style="height: '46%'"
    @click-overlay="datePickerShow = false"
  >
    <van-datetime-picker
      v-model="pickerDate"
      type="datetime"
      title="选择时间"
      @confirm="confirmDate"
      @cancel="datePickerShow = false"
    />
  </van-popup>
</template>

<script>
import { computed, ref } from 'vue'
import { formatTimeStr } from '@/utils/common'

export default {
  props: {
    // 是否选择处理后的
    start: {
      type: String,
      required: true
    },
    end: {
      type: String,
      required: true
    }
  },
  emits: ['update:start', 'update:end', 'search'],
  setup(props, { emit }) {
    const startTime = computed({
      get() {
        return props.start
      },
      set(val) {
        emit('update:start', val)
      }
    })
    const endTime = computed({
      get() {
        return props.end
      },
      set(val) {
        emit('update:end', val)
      }
    })

    const datePickerShow = ref(false)

    let pikerKey = 'start'

    const pickerDate = ref(new Date())

    const pickTime = key => {
      pikerKey = key
      pickerDate.value = new Date(props[key])
      datePickerShow.value = true
    }
    const confirmDate = () => {
      const timeStr = formatTimeStr(pickerDate.value)
      if (pikerKey === 'start') {
        startTime.value = timeStr
      } else {
        endTime.value = timeStr
      }
      datePickerShow.value = false
    }

    return { startTime, endTime, datePickerShow, pickerDate, pickTime, confirmDate }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/mixins.scss';
input {
  @include search-input;
  width: 130px;
}
.chart-timer {
  min-width: 340px;
  margin-top: 12px;
  height: 36px;
  line-height: 36px;
  font-size: 10px;
  &__input {
    min-width: 254px;
    box-sizing: border-box;
  }
  .search-btn {
    min-width: 36px;
    box-sizing: border-box;
  }
}
</style>
