<template>
  <div class="cover-page">
    <NavBar />
    <div class="container">
      <Info :data="baseData" />
      <div class="chart-section card">
        <Tabs :isModify="tabState.showModify" @select="selectSource" />

        <TimeSelect v-model:start="startTime" v-model:end="endTime" @search="searchHandler" />

        <Chart ref="chart" :deviceId="currentDevice" :dateList="markList" :showModify="tabState.showModify" />

        <div class="audit-bottom">
          <div class="audit-option">
            <span>审核意见:</span>
            <textarea cols="30" rows="4" v-model="auditRemark" :disabled="baseData.status !== 0"></textarea>
          </div>
          <div class="audit-operate" v-if="baseData.status === 0">
            <van-button type="primary" @click="submitExamine(true)">通过</van-button>
            <van-button type="danger" @click="submitExamine(false)">不通过</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { Toast } from 'vant'

import http from '@/utils/request'
import { http_clean } from '@/utils/request'
import Info from './components/info.vue'
import Tabs from './components/tabs.vue'
import TimeSelect from './components/time-select.vue'
import Chart from './components/chart.vue'

export default {
  name: 'GPSAuditDetail',
  components: {
    Info,
    Tabs,
    TimeSelect,
    Chart
  },
  setup() {
    const baseData = ref({})

    const store = useStore()

    const tabState = reactive({
      showModify: true,
      datePickerShow: false,
      pickerDate: new Date(),
      pickerKey: ''
    })

    const startTime = ref('')
    const endTime = ref('')
    const currentDevice = ref('')
    const markList = ref([])

    const chart = ref(null)

    const auditRemark = ref('')

    baseData.value = { ...store.state.gpsAudit.selectedRowData }
    const { fixStartTime, fixEndTime, deviceId, dateList } = baseData.value
    startTime.value = fixStartTime
    endTime.value = fixEndTime
    currentDevice.value = deviceId
    markList.value = dateList

    onMounted(() => {
      initData()
      chart.value.askForData(startTime.value, endTime.value)
    })

    const selectSource = async flag => {
      tabState.showModify = flag
      await nextTick()
      chart.value.askForData(startTime.value, endTime.value)
    }

    const searchHandler = async () => {
      await nextTick()
      chart.value.askForData(startTime.value, endTime.value)
    }

    const initData = () => {
      http_clean({
        url: 'system/getDeviceBaseInfoByDeviceId.html',
        method: 'post',
        data: {
          deviceId: baseData.value.deviceId
        }
      }).then(res => {
        if (res.success) {
          const { data } = res
          baseData.value.repaired = data.repaired
          baseData.value.haveRepaired = data.haveRepaired || {}
          if (!data.repaired) {
            baseData.value.isPushText = '不限制进厂'
          } else {
            if (data.repaired.isPush) {
              baseData.value.isPushText = '限制进厂'
            } else {
              baseData.value.isPushText = '不限制进厂'
            }
          }
        }
      })
    }
    const router = useRouter()
    const submitExamine = type => {
      let param = {
        id: baseData.value.id,
        auditRemark: auditRemark.value,
        status: type
      }
      http({
        url: 'projectCompany/gpsAudit.html',
        method: 'post',
        data: param
      }).then(data => {
        Toast(data.message)
        if (data.success) {
          setTimeout(() => {
            router.go(-1)
          }, 100)
        }
      })
    }

    return {
      chart,
      baseData,
      tabState,
      startTime,
      endTime,
      currentDevice,
      markList,
      auditRemark,
      selectSource,
      searchHandler,
      submitExamine
    }
  }
}
</script>
<style lang="scss" scoped>
div {
  color: #455a64;
}
.cover-page {
  z-index: 1000;
}
.audit-option {
  display: flex;
  margin-top: 8px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 14px;
  textarea {
    flex: 1;
    padding: 0 4px;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
    line-height: 14px;
  }
}
.audit-operate {
  display: flex;
  justify-content: space-around;
  padding: 12px;
  button {
    width: 160px;
    height: 30px;
  }
}
</style>
