<template>
  <NavBar>
    <template #right>
      <div class="icon-area">
        <van-icon name="search" @click="onSearch" />
      </div>
    </template>
  </NavBar>
  <div class="container" ref="scrollEl">
    <van-pull-refresh v-model="loadState.pullLoading" @refresh="onRefresh" class="pull-wrapper">
      <van-list
        v-model:loading="loadState.listLoading"
        :finished="loadState.finished"
        finished-text="没有更多了"
        v-model:error="loadState.error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
        class="list"
      >
        <div class="list__item" v-for="item in loadState.listItems" :key="item.id">
          <van-row class="list__item__row">
            <van-col span="12">{{ item.carNumber }}</van-col>
            <van-col span="12">
              <span>
                设备ID:
                <span>{{ item.deviceId }}</span>
              </span>
            </van-col>
          </van-row>

          <van-row class="list__item__row">
            <van-col span="24">
              <span>
                厂区名称:&nbsp;&nbsp;
                <span>{{ item.companyName }}</span>
              </span>
            </van-col>
          </van-row>
          <van-row class="list__item__row">
            <van-col span="24">
              <span>
                创建时间:&nbsp;&nbsp;
                <span>{{ item.createTime }}</span>
              </span>
            </van-col>
          </van-row>

          <van-row class="list__item__row">
            <van-col span="24">
              <span>
                是否解除限制进厂:&nbsp;&nbsp;
                <span v-if="item.limitStatus === 0" class="orange">
                  未解除&nbsp;&nbsp;
                  <van-button type="primary" size="mini" @click="releaseRestrictions(item)">解除</van-button>
                </span>
                <span v-else-if="item.limitStatus === 1" class="green">已解除</span>
              </span>
            </van-col>
          </van-row>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
  <div class="affix" v-if="loadState.listItems > 0">
    <span>
      {{ pager.currentPage }}
    </span>
    <span>/</span>
    <span>
      {{ pager.pageCount }}
    </span>
  </div>
  <router-view v-slot="{ Component }">
    <transition :name="animationName">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { ref, reactive, onMounted, provide, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import http from '@/utils/request'
import { http_clean } from '@/utils/request'
import { useAnimation } from '@/hooks/useAnimation'

import { Toast, Dialog } from 'vant'

export default {
  name: 'DeviceRelieveRecord',
  setup() {
    const queryParams = ref({
      companyName: '',
      factoryId: '',
      carNumber: '',
      deviceId: '',
      limitStatus: '',
      limitStatusStr: '',
      startTime: '',
      endTime: '',
      searchKey: ''
    })
    const { scrollEl, loadState, pager, onRefresh, onLoad, scrollCalc } = usePullLoading(queryParams)

    const { animationName } = useAnimation()

    provide('relieveOptions', queryParams)

    const updateOption = val => {
      queryParams.value = val
    }
    provide('updateRelieveOption', updateOption)

    onMounted(() => {
      onRefresh()
    })
    const router = useRouter()

    const onSearch = () => {
      router.push({
        name: 'DeviceRelieveRecordQuery'
      })
    }
    const route = useRoute()
    watch(
      () => route.fullPath,
      (current, prev) => {
        if (prev === '/settings/device-relieve-record/query') {
          scrollEl.value.scrollTop = 0
          onRefresh()
        }
      }
    )

    // 解除限制
    const releaseRestrictions = item => {
      Dialog.confirm({
        title: '提示',
        message: '此操作将解除限制车辆，确认要解除限制?',
        confirmButtonColor: '#1989fa'
      }).then(() => {
        http({
          url: 'deviceNormalLimitRecord/auditSuccess.html',
          method: 'post',
          data: { id: item.id }
        }).then(res => {
          Toast(res.message)
          if (res.success) {
            item.limitStatus = 1
          }
        })
      })
    }

    return {
      scrollEl,
      loadState,
      pager,
      onRefresh,
      onLoad,
      scrollCalc,
      animationName,
      onSearch,
      releaseRestrictions
    }
  }
}

function usePullLoading(queryParams) {
  const loadState = reactive({
    pullLoading: false, // 下拉刷新状态
    listLoading: false, // list 加载状态
    finished: true, // 是否已经全部完成，默认完成，手动加载第一次
    error: false, // 列表加载错误
    listItems: [] // list 渲染数据
  })

  const pager = reactive({
    queryPage: 1, // 需要查询的页数
    currentPage: 1, // 当前滚动内容所属页
    pageCount: 1 // 总页数，接口返回
  })
  // 下拉刷新
  function onRefresh() {
    loadState.finished = true
    loadState.pullLoading = true
    pager.queryPage = 1
    fetchData()
  }

  // list的加载事件，页面一进入，默认会执行一次
  function onLoad() {
    // 是否全部加载完成
    if (!loadState.finished) {
      pager.queryPage++
      fetchData()
    }
  }

  const scrollEl = ref()

  const scrollCalc = e => {
    let distance = e.target.scrollTop + e.target.getBoundingClientRect().height
    const itemEl = document.querySelector('.list-wrap__item')
    // dom 高度
    const innerHeigh = itemEl.getBoundingClientRect().height
    // 获取css属性
    const cssComAttrs = document.defaultView.getComputedStyle(itemEl)
    const marginT = parseFloat(cssComAttrs.marginTop)
    const elHeight = innerHeigh + marginT

    pager.currentPage =
      Math.ceil(distance / (elHeight * 10)) >= pager.pageCount ? pager.pageCount : Math.ceil(distance / (elHeight * 10))
    if (pager.currentPage < 1) pager.currentPage = 1
  }

  function fetchData() {
    const params = JSON.stringify(queryParams.value)
    const gridPager = `{"pageSize":"10","nowPage":"${pager.queryPage}","parameters":${params}}`
    http_clean({
      url: 'deviceNormalLimitRecord/pageDeviceNormalLimitRecord.html',
      method: 'post',
      data: { gridPager }
    })
      .then(res => {
        if (!res.isSuccess) {
          // 加载失败需要重新请求当前页
          pager.queryPage--
          loadState.pullLoading = false
          loadState.listLoading = false
          loadState.finished = false
          loadState.error = true
          return
        }
        res.exhibitDatas = res.isSuccess ? res.exhibitDatas : []
        setTimeout(() => {
          if (loadState.pullLoading) {
            loadState.listItems = []
          }
          loadState.listItems = loadState.listItems.concat(res.exhibitDatas)
          pager.pageCount = res.pageCount
          // 判断是否全部加载完成
          loadState.finished = pager.queryPage >= pager.pageCount
          loadState.pullLoading = false
          loadState.listLoading = false
        }, 200)
      })
      .catch(() => {
        pager.queryPage--
        loadState.pullLoading = false
        loadState.listLoading = false
        loadState.error = true
      })
  }
  return {
    scrollEl,
    loadState,
    pager,
    onRefresh,
    onLoad,
    scrollCalc
  }
}
</script>

<style lang="scss" scoped>
.container {
  .list {
    font-size: 14px;
    &__item {
      height: 100px;
      box-sizing: border-box;
      overflow-y: hidden;
      background: #f2f2f2;
      margin: 4px 8px 4px;
      padding: 12px;
      border-radius: 6px;
      &__row {
        margin: 2px 6px;
      }
    }
  }
}
</style>
