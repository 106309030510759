import { reactive, ref, onBeforeUnmount, nextTick } from 'vue'
import { useStore } from 'vuex'
import { Toast } from 'vant'
import { toBDMapPointArr, toBDMapPoint } from '@/utils/mapUtil'
import startIcon from '@/assets/bMap/icon_stsrt.png'
import endIcon from '@/assets/bMap/icon_end.png'
import carIcon from '@/assets/bMap/map-indicator-60x60.png'
import http from '@/utils/request'
import { lineGetColor, formatTimeStr, getMilliSecond, getTimeDiff } from '@/utils/common'
// 导入 echarts y轴配置项
import { yAxiSignal, yAxiWeight, yAxiSpeed } from '@/utils/signalChartConst'

export default function () {
  const store = useStore()
  const baseInfo = reactive({
    activeChannels: '',
    coef: '',
    runStatus: 1,
    version: '',
    versionString: '',
    isOverDate: '',
    renewalDueTime: '',
    expireTime: ''
  })

  const deviceId = store.state.optimize.deviceId
  const apiType = store.state.optimize.apiType

  getBaseInfo(deviceId)

  const state = reactive({
    filterTimeDefault: 0,
    legendSleced: {},
    hideOrshowline: [],
    lookSignal: true,
    carSpeed: true, // 是否查看车辆速度
    loadometer: false, //是否查地磅信息的条件
    signal16Arr: [], //16通道的数据
    isRate16: false, //是否计算16通道的误差率
    timeShadow: [], // 数据缺失的时间段进行数据的补点,在echart对应显示灰色区域,表明该时间段范围内没有历史数据.
    allData: [], //echart图后台请求回来的数据
    passageData: [], //所有chart图折线的数组
    linetoMapData: [], //地图联动画轨迹数据
    loadometerData: [], //后台返回的地磅数据
    loadometerArr: [], //拼接成echart数组
    weightArr: [], //重量折线的数据
    carSpeedArr: [], //速度折线的数据
    passageName: [], //ad信号折线名
    passageArr: [], //ad信号数据
    voltageData: [], //设备电压数据,echart显示电压曲线
    voltageDataEchart: [] //设备电压数据,处理后数据
  })
  //	指定图表的配置项和数据
  const eChartOption = ref({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross' //十字星
      },
      formatter: function (params) {
        const paramIndex = params[0].dataIndex
        const pointObj = state.linetoMapData[paramIndex]
        if (pointObj.lat && pointObj.lng) {
          resetMoveInfoBox(pointObj)
        }
        let res = ''
        let len = params.length
        for (let i = 0; i < len; i++) {
          res = res + params[i].marker + params[i].seriesName + ':' + params[i].data + '</br>'
        }
        return res
      }
    },
    grid: {
      left: '8%',
      right: '24%',
      top: '40%',
      bottom: '16%'
    },
    toolbox: {
      feature: {
        dataView: {
          show: false,
          readOnly: false
        },
        restore: {
          show: false
        },
        saveAsImage: {
          show: false
        }
      }
    },
    legend: {
      selected: {}
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    yAxis: [yAxiWeight],
    series: [
      // {
      //   name: '重量',
      //   type: 'line',
      //   data: []
      // }
    ]
  })
  let mapInstance = null
  let movingMarker = null // 地图上车标记
  //移动折线图联动地图移动点
  function resetMoveInfoBox(params) {
    const point = new BMapGL.Point(params.lng, params.lat)
    const icon = new BMapGL.Icon(carIcon, new BMapGL.Size(60, 60), {
      imageSize: new BMapGL.Size(60, 60)
    })
    if (!movingMarker) {
      movingMarker = new BMapGL.Marker(point, {
        icon: icon
      })
      mapInstance.addOverlay(movingMarker)
    }
    movingMarker.setPosition(point)
  }
  // 获取chart数据
  function getData(paras) {
    const { deviceId, startTime, endTime } = paras
    state.loadometerData = []
    state.loadometerArr = []
    state.voltageData = []
    http({
      url: 'system/getDeviceBaseInfoByDeviceId.html',
      method: 'post',
      data: {
        deviceId
      }
    }).then(res => {
      if (res.success && res.data) {
        if (res.data.projectCarInfo) {
          const responce = JSON.parse(JSON.stringify(res.data.projectCarInfo))
          baseInfo.renewalDueTime = responce.renewalDueTime
          if (responce.renewalDueTime) {
            let date = new Date()
            let now = date.getTime()
            let overlineDate = new Date(responce.renewalDueTime).getTime()
            if (overlineDate > now) {
              baseInfo.isOverDate = '未到期'
            } else {
              baseInfo.isOverDate = '已到期'
              baseInfo.expireTime = '过期' + responce.remainingDay.slice(1, responce.remainingDay.length)
            }
          }
        }
      }
    })

    // 获取地磅数据
    http({
      url: 'weighbridge/weightforreal.html',
      method: 'post',
      data: {
        showApi: 0,
        showModify: 0,
        deviceId,
        startTime,
        endTime
      }
    }).then(result => {
      if (result.data.length >= 1) {
        state.loadometerData = result.data
      }
    })
    // 获取电压数据
    http({
      url: 'logForVoltage/queryLogForVoltageByParam.html',
      method: 'post',
      data: {
        showApi: 0,
        showModify: 0,
        deviceId,
        startTime,
        endTime
      }
    }).then(result => {
      if (result.success) {
        state.voltageData = result.data
      }
    })

    let getDateUrl = 'stats/draw.html'
    if (apiType == '2') {
      getDateUrl = 'system/gpsweightAPI.html'
    }
    http({
      url: getDateUrl,
      method: 'post',
      data: {
        deviceId,
        startTime,
        endTime,
        showModify: 1
      }
    }).then(data => {
      state.allData = data.data
      if (state.allData.length < 2) {
        Toast('未获取到相关数据')
        return
      }
      state.linetoMapData = [] //地图联动轨迹画线数据
      state.carSpeedArr = [] //画速度折线数据
      let timeValue = []
      let len = state.allData.length
      let lostWeightMark = [] //计算丢失重量的时长
      let nullGpsArr = [] //经纬度为空或者0.0的画折线数据
      let nullMarkerData = [] //计算经纬度丢失的时长
      let isLostGps = false //是否有丢失经纬度
      let isHavePoint = 0 //控制判断经纬度丢失起始结束位置计算时间条件
      /**
       * 计算采样周期,用于判断重量丢失的阈值
       * 取10组历史数据,取中位数作为数据采样的频率
       */
      let temFilterArr = []
      state.filterTimeDefault = 0
      if (len > 10) {
        for (let i = 0; i < 10; i++) {
          let time1 = changeTime(state.allData[i].uploadDate)
          let time2 = changeTime(state.allData[i + 1].uploadDate)
          let diffTime = (time2 - time1) / 1000
          temFilterArr.push(diffTime)
        }
        temFilterArr.sort(function (a, b) {
          return a - b
        })
        state.filterTimeDefault = Number(temFilterArr[6]) * 1.5
      }

      state.filterTimeDefault = state.filterTimeDefault > 150 ? state.filterTimeDefault : 150

      /**
       * 对筛选时间范围内,返回的历史数据做处理,对于数据缺失的时间段进行数据的补点,在echart对应显示灰色区域,表明该时间段范围内没有历史数据.
       */
      state.timeShadow = []
      if (getTimeDiff(state.allData[0].uploadDate, startTime) > state.filterTimeDefault * 1000) {
        // 判断曲线数据前有没有过磅数据,有过磅数据,显示无数据区域,没有过磅数据则不显示无数据区域
        let hasWeightInEmptyDataStartTime = false
        for (let i = 0; i < state.loadometerData.length; i++) {
          let item = state.loadometerData[i]
          if (
            getMilliSecond(startTime) < getMilliSecond(item.date) &&
            getMilliSecond(item.date) < getMilliSecond(state.allData[0].uploadDate)
          ) {
            hasWeightInEmptyDataStartTime = true
            break
          }
        }
        if (hasWeightInEmptyDataStartTime) {
          let emptyDataStartTime = [] //存放搜索开始时间空数据补点的集合
          for (
            let t = getMilliSecond(startTime);
            t < getMilliSecond(state.allData[0].uploadDate);
            t = t + state.filterTimeDefault * 1000
          ) {
            let temDate = formatTimeStr(new Date(t))
            let temObj = {
              aCH1: '',
              aCH2: '',
              aCH3: '',
              aCH4: '',
              aCH5: '',
              aCH6: '',
              aCH7: '',
              aCH8: '',
              aCH9: '',
              aCH10: '',
              aCH11: '',
              aCH12: '',
              aCH13: '',
              aCH14: '',
              aCH15: '',
              aCH16: '',
              checkGps: '',
              checkWeight: '',
              deviceId: deviceId,
              handBrakeHardwareStatus: '',
              handBrakeSoftwareStatus: '',
              mWeight: '',
              rectifyWeight: '',
              rectifyX: '',
              rectifyY: '',
              speed: '',
              uploadDate: temDate,
              weight: '',
              x: state.allData[0].x,
              y: state.allData[0].y
            }
            emptyDataStartTime.push(temObj)
          }

          let emptyShadowStart = [
            {
              name: '无数据',
              xAxis: startTime
            },
            {
              xAxis: state.allData[0].uploadDate
            }
          ]
          state.timeShadow.push(emptyShadowStart)
          state.allData = emptyDataStartTime.concat(state.allData)
        }
      }
      queryHistory(state.allData)
      if (getTimeDiff(endTime, state.allData[state.allData.length - 1].uploadDate) > state.filterTimeDefault * 1000) {
        // 判断曲线数据后有没有过磅数据,有过磅数据,显示无数据区域,没有过磅数据则不显示无数据区域
        let hasWeightInEmptyDataEndTime = false
        for (let i = 0; i < state.loadometerData.length; i++) {
          let item = state.loadometerData[i]
          if (
            getMilliSecond(state.allData[state.allData.length - 1].uploadDate) < getMilliSecond(item.date) &&
            getMilliSecond(item.date) < getMilliSecond(endTime)
          ) {
            hasWeightInEmptyDataEndTime = true
            break
          }
        }
        if (hasWeightInEmptyDataEndTime) {
          let emptyDataEndTime = [] //存放搜索结束时间空数据补点的集合
          let EndTime = ''
          if (getTimeDiff(new Date(), endTime) > 0) {
            EndTime = state.endTime
          } else {
            EndTime = formatTimeStr(new Date())
          }
          for (
            let t = getMilliSecond(state.allData[state.allData.length - 1].uploadDate) + state.filterTimeDefault * 1000;
            t <= getMilliSecond(EndTime);
            t = t + state.filterTimeDefault * 1000
          ) {
            let temDate = formatTimeStr(new Date(t))
            let temObj = {
              aCH1: '',
              aCH2: '',
              aCH3: '',
              aCH4: '',
              aCH5: '',
              aCH6: '',
              aCH7: '',
              aCH8: '',
              aCH9: '',
              aCH10: '',
              aCH11: '',
              aCH12: '',
              aCH13: '',
              aCH14: '',
              aCH15: '',
              aCH16: '',
              checkGps: '',
              checkWeight: '',
              deviceId: deviceId,
              handBrakeHardwareStatus: '',
              handBrakeSoftwareStatus: '',
              mWeight: '',
              rectifyWeight: '',
              rectifyX: '',
              rectifyY: '',
              speed: '',
              uploadDate: temDate,
              weight: '',
              x: state.allData[state.allData.length - 1].x,
              y: state.allData[state.allData.length - 1].y
            }
            emptyDataEndTime.push(temObj)
          }
          let emptyShadowEnd = [
            {
              name: '无数据',
              xAxis: state.allData[state.allData.length - 1].uploadDate
            },
            {
              xAxis: emptyDataEndTime[emptyDataEndTime.length - 1].uploadDate
            }
          ]
          state.timeShadow.push(emptyShadowEnd)
          state.allData = state.allData.concat(emptyDataEndTime)
        }
      }

      len = state.allData.length

      for (let i = 0; i < len; i++) {
        timeValue[i] = state.allData[i].uploadDate //x时间轴数据
        if (!state.allData[i].weight) {
          state.allData[i].weight = '-'
        }
        state.weightArr[i] = state.allData[i].weight //重量
        let _speed = parseFloat(state.allData[i].speed) //速度
        if (_speed > -1) {
          state.carSpeedArr[i] = parseFloat(state.allData[i].speed)
        } else {
          state.carSpeedArr[i] = 0
        }
        state.loadometerArr[i] = '-'
        nullGpsArr[i] = '-'
        //计算经纬度等于0.0或者丢失的时候是时长start
        if (!state.allData[i] || !state.allData[i].x || state.allData[i].x == '0.0') {
          if (isHavePoint < 1) {
            isHavePoint = i
          }
        } else {
          if (isHavePoint > 0) {
            let lostTime =
              (changeTime(state.allData[i].uploadDate) - changeTime(state.allData[isHavePoint].uploadDate)) / 1000
            if (lostTime > state.filterTimeDefault) {
              let point = {
                value: convertTime(lostTime),
                xAxis: isHavePoint,
                yAxis: state.allData[isHavePoint].weight,
                lostime: lostTime
              }
              nullMarkerData.push(point)
            }
            isHavePoint = 0
          }
        }
        //计算end
        if (i < len - 2) {
          //获取丢失的数据
          let lostStartime = changeTime(state.allData[i].uploadDate)
          let lostEndtime = changeTime(state.allData[i + 1].uploadDate)
          let lostime = (lostEndtime - lostStartime) / 1000
          if (lostime > state.filterTimeDefault) {
            //重量时间段丢失
            let point = {
              value: convertTime(lostime),
              xAxis: i,
              yAxis: state.allData[i].weight,
              lostime: lostime
            }
            lostWeightMark.push(point)
          }
          if (!state.allData[i].x || state.allData[i].x == '0.0') {
            //经纬度坐标丢失
            if (i > 0) {
              isLostGps = true
              nullGpsArr[i] = state.allData[i].weight
            }
          }
        }
        const mapPoint = toBDMapPoint(state.allData[i])
        if (
          mapPoint &&
          mapPoint.lng &&
          mapPoint.lat &&
          mapPoint.lng !== '0.0' &&
          mapPoint.lat !== '0.0' &&
          mapPoint.lat != '0' &&
          mapPoint.lng != '0'
        ) {
          state.linetoMapData[i] = mapPoint
        }
      }

      //查询连续性为空的经纬度数据结束空的位置
      state.passageData = []

      let alertReq = { deviceId: deviceId, startTime: startTime, endTime: endTime }
      let logReq = { deviceId: deviceId, startDate: startTime, endDate: endTime }
      http({
        url: 'alertlog/queryAllTypeDeviceShutDownAlarmList.html',
        method: 'post',
        data: alertReq
      }).then(result => {
        http({
          url: 'log/queryLogByDeviceId.html',
          method: 'post',
          data: logReq
        }).then(data => {
          let alertArr = result.data
          let logArr = data.data
          let temResult = []
          if (alertArr.length > 0) {
            for (let i = 0; i < alertArr.length; i++) {
              alertArr[i].flag = 'alert'
            }
            temResult = temResult.concat(alertArr)
          }
          if (logArr.logForCoeList.length > 0) {
            for (let i = 0; i < logArr.logForCoeList.length; i++) {
              logArr.logForCoeList[i].flag = 'coef'
            }
            temResult = temResult.concat(logArr.logForCoeList)
          }
          if (logArr.UpgradeInfoList.length > 0) {
            for (let i = 0; i < logArr.UpgradeInfoList.length; i++) {
              logArr.UpgradeInfoList[i].flag = 'version'
            }
            temResult = temResult.concat(logArr.UpgradeInfoList)
          }

          let len = temResult.length
          let offlineError = []
          if (len > 0) {
            for (let i = 0; i < len; i++) {
              let tim
              let sort = 0
              let item = temResult[i]
              let topos = {
                lng: item.lng,
                lat: item.lat
              }
              if (topos) {
                item.lng = topos.lng
                item.lat = topos.lat
              }
              if (item.flag == 'alert') {
                tim = changeTime(item.offTime)
              } else if (item.flag == 'coef') {
                tim = changeTime(item.sendTime)
              } else if (item.flag == 'version') {
                tim = changeTime(item.createTime)
              }
              for (let j = sort; j < state.allData.length; j++) {
                let _item = state.allData[j]
                let _tim = changeTime(_item.uploadDate)
                if (_tim - tim >= 0 && j > 0) {
                  sort = j
                  let errorItem
                  if (item.type == 1 && item.flag == 'alert') {
                    errorItem = {
                      name: '离线警报',
                      value: '离',
                      xAxis: j - 1,
                      yAxis: state.allData[j - 1].weight,
                      lostime: '',
                      rangeTime: item.totalSecond ? convertTime(item.totalSecond) : '离线中',
                      symbol: 'image://chartImg/ofline_jb.png',
                      symbolSize: 14,
                      symbolOffset: ['0', '-50%']
                    }
                  } else if (item.type == 2 && item.flag == 'alert') {
                    errorItem = {
                      name: '断电警报',
                      value: '',
                      xAxis: j - 1,
                      yAxis: state.allData[j - 1].weight,
                      lostime: '',
                      rangeTime: item.totalSecond ? convertTime(item.totalSecond) : '断电中',
                      symbol: 'image://chartImg/powerOff2.png',
                      symbolSize: 14,
                      symbolOffset: ['0', '-50%']
                    }
                    //										this.powerOffAlertList.push(item);//断电警报集合,处理echart显示断电区域使用
                  } else if (item.flag == 'coef') {
                    errorItem = {
                      name: '系数设置',
                      value: '系',
                      xAxis: j - 1,
                      yAxis: state.allData[j - 1].weight,
                      lostime: '',
                      symbol: 'image://chartImg/version_icon.png',
                      symbolSize: 16,
                      symbolOffset: ['0', '-50%']
                    }
                  } else if (item.flag == 'version') {
                    errorItem = {
                      name: '固件升级',
                      value: '固',
                      xAxis: j - 1,
                      yAxis: state.allData[j - 1].weight,
                      lostime: '',
                      symbol: 'image://chartImg/version_icon.png',
                      symbolSize: 16,
                      symbolOffset: ['0', '-50%']
                    }
                  }
                  if (errorItem) {
                    for (let keys in item) {
                      errorItem[keys] = item[keys]
                    }
                    offlineError.push(errorItem)
                  }
                  break
                }
              }
            }
          }
          /**
           *   处理警报不在历史数据时间范围之内,但在搜索的时间范围.  将不在历史数据时间范围的数据,补打在历史数据最后时间点上.
           */
          for (let w = 0; w < alertArr.length; w++) {
            let itemAlert = alertArr[w]
            if (changeTime(itemAlert.offTime) > changeTime(state.allData[state.allData.length - 1].uploadDate)) {
              let errorItem
              let index = alertArr.length - w
              if (itemAlert.type == 1 && itemAlert.flag == 'alert') {
                errorItem = {
                  name: '离线警报',
                  value: '离',
                  xAxis: state.allData.length - index,
                  yAxis: state.allData[state.allData.length - index].weight,
                  lostime: '',
                  rangeTime: itemAlert.totalSecond ? convertTime(itemAlert.totalSecond) : '离线中',
                  symbol: 'image://chartImg/ofline_jb.png',
                  symbolSize: 14,
                  symbolOffset: ['0', '-50%']
                }
              } else if (itemAlert.type == 2 && itemAlert.flag == 'alert') {
                errorItem = {
                  name: '断电警报',
                  value: '',
                  xAxis: state.allData.length - index,
                  yAxis: state.allData[state.allData.length - index].weight,
                  lostime: '',
                  rangeTime: itemAlert.totalSecond ? convertTime(itemAlert.totalSecond) : '断电中',
                  symbol: 'image://chartImg/powerOff2.png',
                  symbolSize: 14,
                  symbolOffset: ['0', '-50%']
                }
              }
              if (errorItem) {
                for (let keys in itemAlert) {
                  errorItem[keys] = itemAlert[keys]
                }
                offlineError.push(errorItem)
              }
            }
          }
          for (let i = 0; i < offlineError.length; i++) {
            let item = offlineError[i]
            let isFind = false
            for (let _i = 0; _i < lostWeightMark.length; _i++) {
              let iem = lostWeightMark[_i]
              if (iem.flag) {
                if (item.xAxis == iem.xAxis) {
                  item.xAxis = item.xAxis + 1
                  item.lostime = iem.lostime
                  lostWeightMark.push(item)
                  isFind = true
                  break
                }
              } else {
                if ((item.xAxis < iem.xAxis + 1 && item.xAxis > iem.xAxis - 1) || item.xAxis == iem.xAxis) {
                  item.lostime = iem.lostime
                  lostWeightMark[_i] = item
                  isFind = true
                  break
                }
              }
            }
            if (!isFind) lostWeightMark.push(item)
          }
          state.passageData.push({
            name: '重量（吨）',
            type: 'line',
            borderWidth: 5,
            itemStyle: {
              color: '#c23531'
            },
            data: state.weightArr,
            markArea: {
              itemStyle: {
                color: '#ddd',
                opacity: 0.5
              },
              label: {
                color: '#333',
                fontSize: '16',
                position: ['50%', '50%'],
                offset: [-24, -8]
              },
              emphasis: {
                itemStyle: {
                  color: '#ddd',
                  opacity: 0.5
                },
                label: {
                  position: ['50%', '50%'],
                  offset: [-24, -8]
                }
              },
              data: state.timeShadow
            },
            markPoint: {
              data: lostWeightMark,
              itemStyle: {
                label: {
                  fontSize: 10
                }
              }
            }
          })
          if (isLostGps) {
            state.passageData.push({
              name: '',
              type: 'line',
              itemStyle: {
                color: 'gray'
              },
              lineStyle: {
                width: 3 //折线宽度
              },
              data: nullGpsArr,
              markPoint: {
                data: nullMarkerData
              }
            })
          }
          //时间轴
          eChartOption.value.xAxis.data = timeValue
          showAdSignal()

          if (state.carSpeed) {
            yAxiSpeed.min = 0
            yAxiSpeed.max = 120
            showCarSpeed()
          }
          if (state.loadometer) {
            showLoadometer()
          }
          yAxiSignal.show = state.lookSignal
          yAxiSpeed.show = state.carSpeed
          eChartOption.value.yAxis = [yAxiWeight, yAxiSignal, yAxiSpeed]
          eChartOption.value.series = state.passageData
          state.legendSleced['手刹信号'] = false
          eChartOption.value.legend.selected = state.legendSleced

          http({
            url: 'realtime/getRealTimeDataForBase.html',
            method: 'post',
            data: { deviceId: deviceId }
          }).then(result => {
            if (result.data.unit == '2') {
              eChartOption.value.series[0].name = '重量（千克）'
            }
            // showMapTrack()
            // voltageChange()
          })
          //  end
        })
      })
    })
  }

  //绘画ad信号折线,及丢失数据点.
  function showAdSignal() {
    state.passageName = []
    state.passageArr = []
    let data = state.allData
    let len = data.length
    let wareStatusArr = [] //手刹信号
    //nullPassArr中对应的值为空的时候,从allPassArr移除对应的通道数据,最终得到filterArr过滤后的数据this.passageArr
    let allPassArr = [] //16通道所有的数组
    let nullPassArr = [] //记录通道全部数据是否为空.,默认值为true
    for (let z = 0; z < 16; z++) {
      let newArr = new Array()
      allPassArr.push(newArr)
      nullPassArr.push(true)
    }
    for (let i = 0; i < len; i++) {
      let handSingal = parseFloat(data[i].handBrakeHardwareStatus) * 10 //手刹信号放大10万倍
      if (handSingal >= 0 || handSingal < 0) {
        wareStatusArr[i] = handSingal
      }
      for (let j = 0; j < 16; j++) {
        let name = 'aCH' + (j + 1)
        let passVal = parseFloat(data[i][name]) //获取16个通道的值;
        allPassArr[j][i] = passVal
        if (passVal > 0 || passVal < 0) {
          nullPassArr[j] = false
        }
      }
    }

    state.legendSleced = {}
    for (let _z = 0; _z < nullPassArr.length; _z++) {
      if (!nullPassArr[_z]) {
        let passName = '通道' + (_z + 1)
        state.passageName.push(passName)
        state.passageArr.push(allPassArr[_z])

        if (state.hideOrshowline.indexOf(passName) > -1) {
          state.legendSleced[passName] = true
        } else {
          state.legendSleced[passName] = false
        }
      }
    }
    if (!nullPassArr[15]) {
      // 当16通道的值不为空时候
      state.signal16Arr = allPassArr[15]
      state.isRate16 = true
    } else {
      state.isRate16 = false
    }
    if (wareStatusArr.length > 1) {
      state.passageName.push('手刹信号')
      state.passageArr.push(wareStatusArr)
    }

    if (state.lookSignal) {
      for (let _i = 0; _i < state.passageName.length; _i++) {
        const obj = {
          name: state.passageName[_i],
          type: 'line',
          yAxisIndex: 1,
          itemStyle: {
            color: lineGetColor(_i)
          },
          lineStyle: {
            width: 2, //折线宽度
            opacity: 0.75
          },
          data: state.passageArr[_i]
        }
        if (state.passageName[_i] === '手刹信号') {
          obj.itemStyle = {
            color: '#8B668B'
          }
          obj.yAxisIndex = 0
        }
        state.passageData.push(obj)
      }
    }
  }
  //绘画车辆速度折线
  function showCarSpeed() {
    let obj = {
      name: '车辆速度（km/h）',
      type: 'line',
      yAxisIndex: 2,
      itemStyle: {
        color: '#5793f3',
        lineStyle: {
          width: 1, //折线宽度
          opacity: 0.5
        }
      },
      data: state.carSpeedArr,
      areaStyle: {
        color: 'rgba(83, 146, 230,0.5)'
      }
    }
    state.passageData.push(obj)
  }
  //获取地磅数据在chart图表显示.
  function showLoadometer() {
    let orderIndex = 0
    for (let x = 0; x < state.loadometerData.length; x++) {
      const time1 = getMilliSecond(state.loadometerData[x].date)
      for (let i = orderIndex; i < state.allData.length; i++) {
        const time2 = getMilliSecond(state.allData[i].uploadDate)
        orderIndex++
        if (time2 - time1 >= 0) {
          let lmWeight = parseFloat(state.loadometerData[x].weight)
          loadometerVoluation(i - 1, lmWeight)
          break
        }
      }
    }
    if (state.loadometerData.length >= 1) {
      let obj = {
        name: '地磅数据',
        type: 'scatter',
        yAxisIndex: 0,
        itemStyle: {
          color: 'orange'
        },
        data: state.loadometerArr
      }
      state.passageData.push(obj)
    }
  }
  //地磅数据赋值到对应的折线数据位置上,若数据上有值则往后移
  function loadometerVoluation(sort, weight) {
    if (sort + 1 <= state.loadometerArr.length) {
      if (state.loadometerArr[sort + 1] == '-') {
        state.loadometerArr[sort + 1] = weight
      } else {
        loadometerVoluation(sort + 1, weight)
      }
    }
  }
  //定义转换时间戳函数
  function changeTime(str) {
    let str1 = str.replace(/-/g, '/')
    let timestamp = new Date(str1).getTime()
    return timestamp
  }
  //转换时间格式
  function convertTime(timeInt) {
    let d, h, m
    if (timeInt >= 0) {
      d = Math.floor(timeInt / 60 / 60 / 24)
      h = Math.floor((timeInt / 60 / 60) % 24)
      m = Math.floor((timeInt / 60) % 60)
    }
    if (d < 1) {
      if (h < 1) {
        // 	 return m+'分'+s+'秒';
        return '约' + m + '分'
      } else {
        return h + '小时' + m + '分'
      }
    } else {
      return d + '天' + h + '小时' + m + '分'
    }
  }
  function getBaseInfo(deviceId) {
    http({
      url: 'system/getRealTimeData.html',
      method: 'post',
      data: {
        deviceId
      }
    }).then(res => {
      if (!res.success) return
      const { coef, runStatus, version } = res.data
      baseInfo.coef = coef
      baseInfo.runStatus = runStatus
      baseInfo.version = version
      // baseInfo.signals = signals
      let coefArr = []
      let resultString = ''
      for (let i = 0; i < res.data.coef.length; i++) {
        let item = res.data.coef[i]
        if (item) {
          let vname = '通道' + (i + 1)
          coefArr.push(vname + ':' + item)
          state.hideOrshowline.push(vname)
        }
      }
      for (let _i = 0; _i < coefArr.length; _i++) {
        if (_i < coefArr.length - 1) {
          resultString += coefArr[_i] + ','
        } else {
          resultString += coefArr[_i]
        }
      }
      baseInfo.versionString = resultString
    })
  }
  // 画地图
  async function queryHistory(data) {
    await nextTick()
    mapInstance = new BMapGL.Map('trackContainer')
    mapInstance.enableScrollWheelZoom(true)
    mapInstance.addControl(new BMapGL.ZoomControl())
    mapInstance.addControl(new BMapGL.ScaleControl())
    mapInstance.setDisplayOptions({
      poiIcon: false,
      building: false //是否显示3D建筑物
    })
    mapInstance.clearOverlays()
    let path = []
    data.forEach(el => {
      if (el.x && el.y) {
        path.push({ lng: el.x, lat: el.y })
      }
    })
    // 转换百度坐标系
    path = toBDMapPointArr(path)
    // paint map
    const startPos = drawPoint(path[0], startIcon)
    const endPos = drawPoint(path[path.length - 1], endIcon)
    mapInstance.addOverlay(startPos)
    mapInstance.addOverlay(endPos)
    drawLine(path, mapInstance)
  }

  onBeforeUnmount(() => {
    mapInstance && mapInstance.destroy()
    mapInstance = null
  })
  return {
    eChartOption,
    getData,
    baseInfo,
    state
  }
}

// 地图画点
function drawPoint(position, icon) {
  const posIcon = new BMapGL.Marker(new BMapGL.Point(position.lng, position.lat), {
    offset: new BMapGL.Size(0, -10),
    icon: new BMapGL.Icon(icon, new BMapGL.Size(30, 30), {
      imageSize: new BMapGL.Size(30, 30)
    })
  })
  posIcon.setZIndex(10)
  return posIcon
}
// 地图画线
function drawLine(path, map) {
  let polyList
  if (path[0].hz) {
    polyList = path
  } else {
    polyList = path.map(el => new BMapGL.Point(el.lng, el.lat))
  }
  // 路径转化为点
  const polyline = new BMapGL.Polyline(polyList, {
    strokeColor: '#4C84F1',
    strokeWeight: 5,
    strokeOpacity: 1,
    fillOpacity: 0.5
  })
  map.addOverlay(polyline)
  setTimeout(() => {
    map.setViewport(polyList)
  }, 100)
}
